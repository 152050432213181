import { useToast } from '@chakra-ui/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import {
  CreateShapeFolderDocument,
  DeleteShapeFolderDocument,
  GetShapeFoldersForProjectDeepDocument,
  ShapeFolderDeepFragment,
  UpdateFolderForShapesDocument,
  UpdateShapeFolderDocument,
} from '../gql/graphql';

export type ShapeFoldersReturn = {
  data: ShapeFolderDeepFragment[] | undefined;
  fetching: boolean;
  error: Error | undefined;
  createFolder: (name: string) => Promise<ShapeFolderDeepFragment | undefined>;
  deleteFolder: (id: string) => Promise<void>;
  renameFolder: (id: string, name: string) => Promise<void>;
  findNextAvailableFolderName: (name: string) => string;
  moveShapesToFolder: (
    shapeIds: string[],
    sheetShapeIds: string[],
    folderId: string
  ) => Promise<void>;
  removeShapesFromFolder: (
    shapeIds: string[],
    sheetShapeIds: string[]
  ) => Promise<void>;
  selectedFolder: string | null;
  setSelectedFolder: (folderId: string | null) => void;
};

const ShapeFoldersContext = createContext<ShapeFoldersReturn | null>(null);

export const ShapeFoldersProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { projectId } = useParams<{ projectId: string }>() as {
    projectId: string;
  };

  const [{ data: shapesData, error: shapesError, fetching: shapesFetching }] =
    useQuery({
      query: GetShapeFoldersForProjectDeepDocument,
      variables: { projectId },
    });

  const [, updateFolderForShapesMutation] = useMutation(
    UpdateFolderForShapesDocument
  );

  const [, createShapeFolderMutation] = useMutation(CreateShapeFolderDocument);
  const [, updateShapeFolderMutation] = useMutation(UpdateShapeFolderDocument);
  const [, deleteShapeFolderMutation] = useMutation(DeleteShapeFolderDocument);

  const toast = useToast();

  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);

  const shapeFoldersRef = useRef(shapesData?.project?.shapeFolders);

  useEffect(() => {
    if (shapesData?.project?.shapeFolders) {
      shapeFoldersRef.current = shapesData.project.shapeFolders;
    }
  }, [shapesData?.project?.shapeFolders]);

  const moveShapesToFolder = useCallback(
    async (shapeIds: string[], sheetShapeIds: string[], folderId: string) => {
      const { error: requestError } = await updateFolderForShapesMutation({
        input: {
          id: projectId,
          patch: {
            shapes: {
              updateById: shapeIds.map((id) => ({
                id,
                patch: { folder: folderId },
              })),
            },
            sheetShapes: {
              updateById: sheetShapeIds.map((id) => ({
                id,
                patch: { folder: folderId },
              })),
            },
          },
        },
      });

      if (requestError) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not change folder. Please try again',
        });
        throw requestError;
      }
    },
    [projectId, toast, updateFolderForShapesMutation]
  );

  const findNextAvailableFolderName = useCallback((name: string) => {
    const existingNames = shapeFoldersRef.current?.map((folder) => folder.name);

    if (!existingNames) {
      return name;
    }

    let newName = name;
    let i = 1;
    while (existingNames.includes(newName)) {
      newName = `${name} (${i})`;
      i += 1;
    }

    return newName;
  }, []);

  const removeShapesFromFolder = useCallback(
    async (shapeIds: string[], sheetShapeIds: string[]) => {
      const result = await updateFolderForShapesMutation({
        input: {
          id: projectId,
          patch: {
            shapes: {
              updateById: shapeIds.map((id) => ({
                id,
                patch: { folder: null },
              })),
            },
            sheetShapes: {
              updateById: sheetShapeIds.map((id) => ({
                id,
                patch: { folder: null },
              })),
            },
          },
        },
      });

      if (result.error) {
        console.error(result.error);
        toast({
          status: 'error',
          title: 'Error',
          description: 'An error occurred when removing shape from folder.',
        });
      }
    },
    [projectId, toast, updateFolderForShapesMutation]
  );

  const createFolder = useCallback(
    async (name: string) => {
      const result = await createShapeFolderMutation({
        input: { shapeFolder: { name, projectId } },
      });

      if (result.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not create group. Please try again',
        });
        throw result.error;
      } else {
        toast({
          title: `Created group ${result.data?.createShapeFolder?.shapeFolder?.name}`,
          status: 'success',
        });

        const shapeFolderResult = result.data?.createShapeFolder?.shapeFolder;
        if (!shapeFolderResult) {
          throw new Error('shapeFolder was undefined');
        }

        return shapeFolderResult as ShapeFolderDeepFragment;
      }
    },
    [createShapeFolderMutation, projectId, toast]
  );

  const renameFolder = useCallback(
    async (id: string, name: string) => {
      const result = await updateShapeFolderMutation({
        input: { id, patch: { name } },
      });

      if (result.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not rename group. Please try again',
        });
        throw result.error;
      }
    },
    [toast, updateShapeFolderMutation]
  );

  const deleteFolder = useCallback(
    async (id: string) => {
      const result = await deleteShapeFolderMutation({
        input: { id },
      });

      if (result.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not delete group. Please try again',
        });
        throw result.error;
      }
    },
    [deleteShapeFolderMutation, toast]
  );

  const contextValue = useMemo<ShapeFoldersReturn>(
    () => ({
      data: shapesData?.project?.shapeFolders,
      fetching: shapesFetching,
      error: shapesError,
      createFolder,
      moveShapesToFolder,
      findNextAvailableFolderName,
      removeShapesFromFolder,
      renameFolder,
      deleteFolder,
      selectedFolder,
      setSelectedFolder,
    }),
    [
      createFolder,
      deleteFolder,
      moveShapesToFolder,
      findNextAvailableFolderName,
      removeShapesFromFolder,
      renameFolder,
      selectedFolder,
      shapesData?.project?.shapeFolders,
      shapesError,
      shapesFetching,
    ]
  );

  return (
    <ShapeFoldersContext.Provider value={contextValue}>
      {children}
    </ShapeFoldersContext.Provider>
  );
};

export const useShapeFolders = () => {
  const context = useContext(ShapeFoldersContext);

  if (!context) {
    throw new Error(
      'useShapeFolders must be used within a ShapeFoldersProvider'
    );
  }

  return context;
};
