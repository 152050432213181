import { saveAs } from 'file-saver';
import { utils as XLSXutils, write as XLSXwrite } from 'xlsx';

type TableExcelOptions = {
  filename: string;
  headers: string[];
  rows: Array<Array<string | number | null>>;
};

export function exportTableToExcel({
  headers,
  rows,
  filename,
}: TableExcelOptions) {
  const ws = XLSXutils.aoa_to_sheet([headers, ...rows]);
  const wb = XLSXutils.book_new();
  XLSXutils.book_append_sheet(wb, ws, 'Sheet1');
  const wbout = XLSXwrite(wb, {
    bookType: 'xlsx',
    type: 'binary',
  });
  const blob = new Blob([stringToArrayBuffer(wbout)], {
    type: 'application/octet-stream',
  });

  saveAs(blob, filename);
}

function stringToArrayBuffer(s: string) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
