import { ToastId, useToast } from '@chakra-ui/react';
import { groupBy, sortBy, uniqueId } from 'lodash';
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useMutation, useQuery } from 'urql';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '../components/common/BreadcrumbProvider';
import { useViewer } from '../components/common/ForgeViewer';
import {
  InitialShapeDrawingState,
  LineStringShapeDrawingResult,
  PointDrawingResult,
  PolygonShapeDrawingResult,
  ShapeDrawing,
  ShapeDrawingMode,
  ShapeDrawingResult,
  ShapeDrawingResultType,
} from '../components/orderContractor/shapes/ShapeDrawing';
import {
  toMultiPolygon,
  toPlane,
} from '../domain/geometry/algorithms/util/type-mapping';
import { Point, Triangle } from '../domain/geometry/geometric-types';
import {
  BulkUpsertSparkelPropertiesDocument,
  CreateShapeDocument,
  CreateShapeInput,
  GetShapesForProjectDeepDocument,
  MultiPolygon3Type,
  ShapeDeepFragment,
  UpdateShapeDocument,
  UpdateShapeInput,
} from '../gql/graphql';
import { EventName, useMixpanel } from '../services/mixpanel';
import { calculatePlaneEquation } from '../domain/geometry/algorithms/util/plane';
import { findNextAvailableNameForCopy } from '../utils/naming-utils';
import { getShapeUrn } from '../services/viewer/ShapesManager';
import { SetCursorTool } from '../components/common/forgeViewerInteractions/SetCursorTool';
import { MoveOrCopy3DShapes } from '../components/orderContractor/shapes/MoveOrCopyShapes';
import { isShapeSelected } from '../components/orderContractor/ShapeItem';
import { useSelection } from '../services/viewer/selection';
import { useUserTenant } from '../services/auth-info';
import { useShapes } from './shapes';
import { useShapeFolders } from './shape-folders';
import { usePropertyContext } from './property-resolving';
import { resolveSparkelProperties } from 'src/domain/property-operations';

type ShapeDrawingState =
  | {
      isDrawing: false;
      mode: undefined;
      isExtruding?: undefined;
      thickness?: undefined;
      hasResult: false;
    }
  | {
      isDrawing: true;
      mode: ShapeDrawingMode;
      isExtruding?: undefined;
      thickness?: undefined;
      // Opted for only exposing this to the outside world for now, but feel free to add the actual result if needed
      hasResult: boolean;
    }
  | {
      isDrawing: true;
      mode: ShapeDrawingMode.Polygon | ShapeDrawingMode.MagicPolygon;
      isExtruding: boolean;
      thickness: number;
      hasResult: true;
    };

export type ShapesContextValue = {
  drawing: ShapeDrawingState & {
    activate: (mode?: ShapeDrawingMode) => void;
    editShape: (shapeToEdit: string) => void;
    moveOrCopyShapes: (
      shapeIds: string[],
      translationVector: Point,
      shouldCopy?: boolean
    ) => void;
    deactivate: () => void;
    submit: () => Promise<void>;
    submitError: Error | undefined;
    isSubmitting: boolean;
    shapeToEdit: string | null;
    isMovingShapes: boolean;
    setMovingShapes: (isMoving: boolean) => void;
    isCopyingShapes: boolean;
    setCopyingShapes: (isCopying: boolean) => void;
    setIsExtruding: (isExtruding: boolean) => void;
    setThickness: (thickness: number) => void;
  };
  shapes: {
    data: ShapeDeepFragment[] | undefined;
    fetching: boolean;
    error: Error | undefined;
  };
};

const ShapesContext = createContext<ShapesContextValue | null>(null);

export const BimShapesProvider = ({ children }: { children: ReactElement }) => {
  const { projectId } = useParams<{ projectId: string }>() as {
    projectId: string;
  };

  const [{ data: shapesData, error: shapesError, fetching: shapesFetching }] =
    useQuery({
      query: GetShapesForProjectDeepDocument,
      variables: { projectId },
    });

  const { selectedFolder } = useShapeFolders();
  const { getNewShapeName } = useShapes(projectId);

  const { propertyContext, error: propertyCtxError } =
    usePropertyContext(projectId);
  const [, bulkCreateSparkelProps] = useMutation(
    BulkUpsertSparkelPropertiesDocument
  );

  const [createShapeResult, createShapeMutation] =
    useMutation(CreateShapeDocument);
  const [updateShapeResult, updateShapeMutation] =
    useMutation(UpdateShapeDocument);

  const shapes = useMemo(
    () =>
      shapesData?.project?.shapes
        ? sortBy(shapesData?.project?.shapes, (shape) => shape.createdAt)
        : null,
    [shapesData?.project?.shapes]
  );

  const shapesInternalRef = useRef(shapes);
  useEffect(() => {
    shapesInternalRef.current = shapes;
  }, [shapes]);

  const [drawingModeKey, setDrawingModeKey] = useState(uniqueId());

  const toast = useToast();

  const {
    viewer,
    shapesManager: { renderShapes, unrenderShapes },
    visibilityManager: {
      hideShape,
      showShape,
      isolatedElements,
      addToIsolation,
      hiddenModelUrns,
    },
  } = useViewer();

  const { tenant } = useUserTenant();

  const { t } = useTranslation('project');

  const [shapeIdToEdit, setShapeIdToEdit] = useState<string | null>(null);
  const [drawingResult, setDrawingResult] = useState<ShapeDrawingResult | null>(
    null
  );

  const [isDrawingShape, setDrawingShape] = useState(false);
  const [isMovingShapes, setMovingShapes] = useState(false);
  const [isCopyingShapes, setCopyingShapes] = useState(false);
  const [drawingMode, setDrawingMode] = useState<ShapeDrawingMode>(
    ShapeDrawingMode.MagicPolygon
  );
  const [drawingInitialState, setDrawingInitialState] =
    useState<InitialShapeDrawingState[typeof drawingMode]>();
  const [isExtruding, setIsExtruding] = useState(false);
  const [thickness, setThickness] = useState<number | undefined>();

  const shapeToEdit = shapes?.find((shape) => shape.id === shapeIdToEdit);

  const isSubmitting = createShapeResult.fetching || updateShapeResult.fetching;

  const hasDrawingResult = !!drawingResult && drawingResult.valid;

  const isMovingOrCopyingToastRef = useRef<ToastId>();

  const activateDrawing = useCallback(
    // eslint-disable-next-line complexity
    (newMode?: ShapeDrawingMode) => {
      setDrawingShape(true);
      viewer?.clearSelection();

      if (newMode) {
        setDrawingMode(newMode);

        if (
          (newMode === ShapeDrawingMode.MagicPolygon ||
            newMode === ShapeDrawingMode.Polygon) &&
          drawingResult?.type === ShapeDrawingResultType.Polygon &&
          drawingResult.valid
        ) {
          setDrawingInitialState({
            multipolygon: drawingResult.multipolygon,
            plane: drawingResult.plane,
          });
        } else if (
          (newMode === ShapeDrawingMode.MagicPolygon ||
            newMode === ShapeDrawingMode.Polygon) &&
          drawingResult?.type === ShapeDrawingResultType.ExtrudedPolygon &&
          drawingResult.valid
        ) {
          const defaultThickness = drawingResult.thickness ?? 3.28084;
          setIsExtruding(true);
          setThickness(defaultThickness);
          setDrawingInitialState({
            multipolygon: drawingResult.multipolygon,
            plane: drawingResult.plane,
            thickness: defaultThickness,
          });
        } else {
          setDrawingInitialState(undefined);
          setDrawingResult(null);
        }
      }
    },
    [drawingResult, viewer]
  );

  const deactivateDrawing = useCallback(() => {
    setShapeIdToEdit(null);
    setDrawingShape(false);
    setDrawingResult(null);
    setDrawingInitialState(undefined);
    setDrawingModeKey(uniqueId());
  }, []);

  const editShape = useCallback(
    (shapeId: string) => {
      const shapeToEdit = shapesInternalRef.current?.find(
        (shape) => shape.id === shapeId
      );
      if (!shapeToEdit) {
        throw new Error('Shape not found');
      }
      setIsExtruding(false);
      if (shapeToEdit.polygon) {
        setDrawingMode(ShapeDrawingMode.Polygon);
        setDrawingInitialState({
          multipolygon: toMultiPolygon(shapeToEdit.polygon.multipolygon),
          plane: toPlane(shapeToEdit.polygon.plane),
        });
      } else if (shapeToEdit.extrudedPolygon) {
        setDrawingMode(ShapeDrawingMode.Polygon);
        setIsExtruding(true);
        setThickness(shapeToEdit.extrudedPolygon.thickness);
        setDrawingInitialState({
          multipolygon: toMultiPolygon(
            shapeToEdit.extrudedPolygon.multipolygon
          ),
          plane: toPlane(shapeToEdit.extrudedPolygon.plane),
          thickness: shapeToEdit.extrudedPolygon.thickness,
        });
      } else if (shapeToEdit.line) {
        setDrawingMode(ShapeDrawingMode.LineString);
        setDrawingInitialState({
          points: shapeToEdit.line.points.map(
            (point) => [point.x, point.y, point.z] as Point
          ),
        });
      } else if (shapeToEdit.shapePoint) {
        setDrawingMode(ShapeDrawingMode.Point);
        setDrawingInitialState({
          point: [
            shapeToEdit.shapePoint.point.x,
            shapeToEdit.shapePoint.point.y,
            shapeToEdit.shapePoint.point.z,
          ] as Point,
        });
      } else {
        throw new Error('Shape type not supported');
      }
      setDrawingShape(true);
      setShapeIdToEdit(shapeId);
    },
    [setDrawingMode]
  );

  // Set the cursor to a crosshair when moving or copying
  useEffect(() => {
    if (!viewer || (!isMovingShapes && !isCopyingShapes)) {
      return;
    }

    const viewerRef = viewer?.container;

    if (!viewerRef) {
      return;
    }

    const setCursorTool = new SetCursorTool(
      `url(/src/assets/icons/${
        isCopyingShapes ? 'copy' : 'move'
      }-shape-icon.png), crosshair`
    );

    if (viewer) {
      viewer.toolController.registerTool(setCursorTool);
      viewer.toolController.activateTool(setCursorTool.getName());
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (viewer) {
        viewer.toolController.deregisterTool(setCursorTool);
        viewer.toolController.deactivateTool(setCursorTool.getName());
      }
    };
  }, [isCopyingShapes, isMovingShapes, viewer]);

  const getCopyShapeMutationInput = useCallback(
    (
      shape: ShapeDeepFragment,
      translationVector: Point,
      newName: string,
      viewer: Autodesk.Viewing.GuiViewer3D
    ) => {
      if (shape.polygon) {
        const newMultipolygon: MultiPolygon3Type = JSON.parse(
          JSON.stringify(shape.polygon.multipolygon)
        );
        newMultipolygon.polygons.forEach((polygon) => {
          polygon.exterior.points.forEach((point) => {
            point.x += translationVector[0];
            point.y += translationVector[1];
            point.z += translationVector[2];
          });
          polygon.interiors.forEach((interior) => {
            interior.points.forEach((point) => {
              point.x += translationVector[0];
              point.y += translationVector[1];
              point.z += translationVector[2];
            });
          });
        });

        // Recalculate plane with the first polygon's exterior points
        const newPlane = calculatePlaneEquation(
          newMultipolygon.polygons[0].exterior.points
            .slice(0, 3)
            .map((point) => [point.x, point.y, point.z]) as Triangle
        );

        return getCreatePolygonInput(
          {
            valid: true,
            multipolygon: toMultiPolygon(newMultipolygon),
            plane: newPlane,
          },
          viewer,
          projectId,
          newName,
          shape.folder ?? undefined
        );
      } else if (shape.extrudedPolygon) {
        const newMultipolygon: MultiPolygon3Type = JSON.parse(
          JSON.stringify(shape.extrudedPolygon.multipolygon)
        );
        newMultipolygon.polygons.forEach((polygon) => {
          polygon.exterior.points.forEach((point) => {
            point.x += translationVector[0];
            point.y += translationVector[1];
            point.z += translationVector[2];
          });
          polygon.interiors.forEach((interior) => {
            interior.points.forEach((point) => {
              point.x += translationVector[0];
              point.y += translationVector[1];
              point.z += translationVector[2];
            });
          });
        });

        // Recalculate plane with the first polygon's exterior points
        const newPlane = calculatePlaneEquation(
          newMultipolygon.polygons[0].exterior.points
            .slice(0, 3)
            .map((point) => [point.x, point.y, point.z]) as Triangle
        );

        return getCreateExtrudedPolygonInput(
          {
            valid: true,
            multipolygon: toMultiPolygon(newMultipolygon),
            plane: newPlane,
            thickness: shape.extrudedPolygon.thickness,
          },
          viewer,
          projectId,
          newName,
          shape.folder ?? undefined
        );
      } else if (shape.line) {
        const newPoints = shape.line.points.map((point) => ({
          x: point.x + translationVector[0],
          y: point.y + translationVector[1],
          z: point.z + translationVector[2],
        }));

        return getCreateLineInput(
          {
            valid: true,
            points: newPoints.map((point) => [point.x, point.y, point.z]),
          },
          viewer,
          projectId,
          newName,
          shape.folder ?? undefined
        );
      } else if (shape.shapePoint) {
        return getCreatePointInput(
          {
            valid: true,
            point: [
              shape.shapePoint.point.x + translationVector[0],
              shape.shapePoint.point.y + translationVector[1],
              shape.shapePoint.point.z + translationVector[2],
            ],
          },
          projectId,
          newName,
          shape.folder ?? undefined
        );
      }

      return null;
    },
    [projectId]
  );

  // Accept the old shape and the input that's going to be used to create the new shape
  const copyShapeAndItsAttributes = useCallback(
    async (shape: ShapeDeepFragment, shapeCopyData: CreateShapeInput) => {
      const { data, error } = await createShapeMutation({
        input: shapeCopyData,
      });
      if (error) throw error;
      const newShapeDbId = data?.createShape?.shape?.dbId;
      if (newShapeDbId) {
        const sparkelProps = resolveSparkelProperties(propertyContext, [
          { dbIds: [shape.dbId], modelUrn: shape.urn },
        ]);

        // TODO: I think we can do better and add a method that creates all properties in a single call instead of traversing the network n times where n is the number of properties
        const sparkelPropsCreationPromises = sparkelProps.map((prop) =>
          bulkCreateSparkelProps({
            input: {
              projectId,
              dbIds: [{ dbIds: [newShapeDbId], modelUrn: prop.modelUrn }],
              thePropertySet: prop.attribute.name,
              thePropertyValue: prop.value.toString(),
            },
          })
        );
        const resp = await Promise.all(sparkelPropsCreationPromises);
        resp.forEach(({ error }) => {
          if (error) throw error;
        });
      }
    },
    [projectId, propertyContext]
  );

  const moveOrCopyShapes = useCallback(
    // eslint-disable-next-line complexity
    async (
      shapeIds: string[],
      translationVector: Point,
      shouldCopy = false
    ) => {
      setMovingShapes(false);
      setCopyingShapes(false);

      if (!shapes || !viewer) {
        console.error('Shapes not loaded successfully');
        return;
      }

      viewer.select([]);

      let updatedExistingNames = shapes.map((shape) => shape.name);

      const shapeOperations = [];

      for (const shapeId of shapeIds) {
        const shapeToProcess = shapes.find((shape) => shape.id === shapeId);
        if (!shapeToProcess || !viewer) {
          console.error('Shape not found');
          continue;
        }

        if (shouldCopy) {
          // Generate a unique new name for the copied shape
          const newName = findNextAvailableNameForCopy(
            shapeToProcess.name,
            updatedExistingNames
          );
          updatedExistingNames.push(newName); // Update the list of existing names to include this new name

          // Assume we have a function to prepare the shape data for copying
          // which adjusts the shape's position based on the translationVector and assigns the new name.
          const shapeCopyData = getCopyShapeMutationInput(
            shapeToProcess,
            translationVector,
            newName,
            viewer
          );

          if (shapeCopyData) {
            // Add copying of shape and it's attributes to the shape operations list
            shapeOperations.push(
              copyShapeAndItsAttributes(shapeToProcess, shapeCopyData)
            );
          }
        } else {
          // For moving, update the shape's position based on the translationVector.
          // Assume getUpdateShapeData returns the necessary mutation input for the move.
          const shapeMoveData = getMoveShapeMutationInput(
            shapeToProcess,
            translationVector,
            viewer
          );

          if (shapeMoveData) {
            // Add the move operation promise to the list
            shapeOperations.push(updateShapeMutation({ input: shapeMoveData }));
          }
        }
      }

      isMovingOrCopyingToastRef.current = toast({
        title: shouldCopy ? t('shapes.copying') : t('shapes.moving'),
        status: 'info',
      });

      // Execute all shape operations (copy or move)
      try {
        await Promise.all(shapeOperations);
        toast({
          status: 'success',
          title: t('shapes.success.title'),
          description: shouldCopy
            ? t('shapes.success.copy')
            : t('shapes.success.move'),
        });
      } catch (error) {
        toast({
          status: 'error',
          title: t('shapes.error.title'),
          description: shouldCopy
            ? t('shapes.error.copy')
            : t('shapes.error.move'),
        });
        throw error;
        // Additional error handling logic could be implemented here, such as reverting partially completed operations
      } finally {
        toast.close(isMovingOrCopyingToastRef.current);
      }
    },
    [
      shapes,
      viewer,
      toast,
      t,
      getCopyShapeMutationInput,
      createShapeMutation,
      updateShapeMutation,
    ]
  );

  function getMoveShapeMutationInput(
    shape: ShapeDeepFragment,
    translationVector: Point,
    viewer: Autodesk.Viewing.GuiViewer3D
  ) {
    if (shape.polygon) {
      const newMultipolygon: MultiPolygon3Type = JSON.parse(
        JSON.stringify(shape.polygon.multipolygon)
      ); // Deep copy to avoid mutation

      newMultipolygon.polygons.forEach((polygon) => {
        polygon.exterior.points.forEach((point) => {
          point.x += translationVector[0];
          point.y += translationVector[1];
          point.z += translationVector[2];
        });
        polygon.interiors.forEach((interior) => {
          interior.points.forEach((point) => {
            point.x += translationVector[0];
            point.y += translationVector[1];
            point.z += translationVector[2];
          });
        });
      });

      // Recalculate plane with the first polygon's exterior points
      const newPlane = calculatePlaneEquation(
        newMultipolygon.polygons[0].exterior.points
          .slice(0, 3)
          .map((point) => [point.x, point.y, point.z]) as Triangle
      );

      return getUpdatePolygonInput(
        shape,
        {
          valid: true,
          multipolygon: toMultiPolygon(newMultipolygon),
          plane: newPlane,
        },
        viewer
      );
    } else if (shape.extrudedPolygon) {
      const newMultipolygon: MultiPolygon3Type = JSON.parse(
        JSON.stringify(shape.extrudedPolygon.multipolygon)
      ); // Deep copy to avoid mutation

      newMultipolygon.polygons.forEach((polygon) => {
        polygon.exterior.points.forEach((point) => {
          point.x += translationVector[0];
          point.y += translationVector[1];
          point.z += translationVector[2];
        });
        polygon.interiors.forEach((interior) => {
          interior.points.forEach((point) => {
            point.x += translationVector[0];
            point.y += translationVector[1];
            point.z += translationVector[2];
          });
        });
      });

      // Recalculate plane with the first polygon's exterior points
      const newPlane = calculatePlaneEquation(
        newMultipolygon.polygons[0].exterior.points
          .slice(0, 3)
          .map((point) => [point.x, point.y, point.z]) as Triangle
      );

      return getUpdateExtrudedPolygonInput(
        shape,
        {
          valid: true,
          multipolygon: toMultiPolygon(newMultipolygon),
          plane: newPlane,
          thickness: shape.extrudedPolygon.thickness,
        },
        viewer
      );
    } else if (shape.line) {
      const newPoints = shape.line.points.map((point) => ({
        x: point.x + translationVector[0],
        y: point.y + translationVector[1],
        z: point.z + translationVector[2],
      }));

      return getUpdateLineInput(
        shape,
        {
          valid: true,
          points: newPoints.map((point) => [point.x, point.y, point.z]),
        },
        viewer
      );
    } else if (shape.shapePoint) {
      return getUpdatePointInput(
        shape,
        {
          valid: true,
          point: [
            shape.shapePoint.point.x + translationVector[0],
            shape.shapePoint.point.y + translationVector[1],
            shape.shapePoint.point.z + translationVector[2],
          ],
        },
        viewer
      );
    }

    return null;
  }

  const updateShape = useCallback(async () => {
    invariant(shapeToEdit, 'No shape is being edited');
    invariant(viewer, 'Viewer not loaded');
    invariant(drawingResult, 'No drawing result');
    const input = getUpdateShapeInput(shapeToEdit, drawingResult, viewer);

    const result = await updateShapeMutation({
      input,
    });

    if (result.error) {
      toast({
        status: 'error',
        title: t('shapes.error.title'),
        description: t('shapes.error.update'),
      });
      throw result.error;
    } else {
      toast({
        title: t('shapes.success.update'),
        status: 'success',
      });
      deactivateDrawing();
      setShapeIdToEdit(null);
      if (
        result.data?.updateShape?.shape?.dbId &&
        Object.values(isolatedElements).flat().length > 0
      ) {
        addToIsolation({
          [shapeToEdit.urn]: [result.data.updateShape.shape.dbId],
        });
      }
    }
  }, [
    addToIsolation,
    deactivateDrawing,
    drawingResult,
    isolatedElements,
    shapeToEdit,
    t,
    toast,
    updateShapeMutation,
    viewer,
  ]);

  const createShape = useCallback(async () => {
    invariant(viewer, 'viewer must be defined');
    invariant(shapes, 'Shapes must be defined');
    invariant(drawingResult, 'Shapes must be defined');

    const shapeName = getNewShapeName(tenant?.group ?? '', drawingResult.type);

    const input = getCreateShapeInput(
      drawingResult,
      viewer,
      projectId,
      shapeName
    );

    if (selectedFolder) {
      input.shape.shapeFolder = {
        connectById: {
          id: selectedFolder,
        },
      };
    }
    const result = await createShapeMutation({
      input,
    });

    if (result.error) {
      toast({
        status: 'error',
        title: t('shapes.error.title'),
        description: t('shapes.error.create'),
      });
      throw result.error;
    } else {
      toast({
        title: t('shapes.success.create'),
        status: 'success',
      });
      deactivateDrawing();
      if (
        result.data?.createShape?.shape?.dbId &&
        Object.values(isolatedElements).flat().length > 0
      ) {
        addToIsolation({
          [result.data.createShape.shape.urn]: [
            result.data.createShape.shape.dbId,
          ],
        });
      }
    }
  }, [
    viewer,
    shapes,
    drawingResult,
    getNewShapeName,
    tenant?.group,
    projectId,
    selectedFolder,
    createShapeMutation,
    toast,
    t,
    deactivateDrawing,
    isolatedElements,
    addToIsolation,
  ]);

  const { projectName, orderName } = useBreadcrumb();

  const { trackEvent } = useMixpanel();

  const submitDrawing = useCallback(async () => {
    if (!drawingResult || !drawingResult.valid) {
      throw new Error('No result to submit');
    }
    if (shapeIdToEdit) {
      await updateShape();
    } else {
      await createShape();

      trackEvent(EventName.ShapeCreated, {
        'Viewer Mode': 'models',
        'Shape Type': drawingResult.type,
        'Project Name': projectName,
        'Project ID': projectId,
        'Table Name': orderName,
      });
    }
  }, [
    createShape,
    drawingResult,
    orderName,
    projectName,
    projectId,
    shapeIdToEdit,
    trackEvent,
    updateShape,
  ]);

  useEffect(() => {
    if (shapes) {
      const shapesByUrn = groupBy(shapes, (shape) => shape.urn);
      for (const urn of Object.keys(shapesByUrn)) {
        renderShapes(urn, shapesByUrn[urn]);
      }
      return () => {
        for (const urn of Object.keys(shapesByUrn)) {
          unrenderShapes(urn);
        }
      };
    }
  }, [renderShapes, shapes, unrenderShapes]);

  // Hook for deactivating drawing if the shape to edit is deleted
  useEffect(() => {
    if (shapes !== null) {
      const shapeIds = shapes.map((shape) => shape.id);
      if (
        isDrawingShape &&
        shapeIdToEdit &&
        !shapeIds.includes(shapeIdToEdit)
      ) {
        deactivateDrawing();
      }
    }
  }, [deactivateDrawing, isDrawingShape, shapeIdToEdit, shapes]);

  // Hook for hiding the shape that is being edited
  useEffect(() => {
    if (shapeToEdit && isDrawingShape) {
      hideShape(shapeToEdit.urn, shapeToEdit.dbId);
      return () => {
        showShape(shapeToEdit.urn, shapeToEdit.dbId);
      };
    }
  }, [isDrawingShape, hideShape, shapeToEdit, showShape]);

  const drawingState: ShapeDrawingState = useMemo(() => {
    if (!isDrawingShape) {
      return {
        isDrawing: false,
        hasResult: false,
      } as ShapeDrawingState;
    } else {
      if (
        drawingMode === ShapeDrawingMode.Polygon ||
        drawingMode === ShapeDrawingMode.MagicPolygon
      ) {
        return {
          isDrawing: true,
          hasResult: hasDrawingResult,
          mode: drawingMode,
          isExtruding,
          thickness,
        } as ShapeDrawingState;
      }
      return {
        isDrawing: true,
        hasResult: hasDrawingResult,
        mode: drawingMode,
      };
    }
  }, [drawingMode, hasDrawingResult, isDrawingShape, isExtruding, thickness]);

  const contextValue = useMemo<ShapesContextValue>(
    () => ({
      drawing: {
        activate: activateDrawing,
        editShape: editShape,
        moveOrCopyShapes: moveOrCopyShapes,
        deactivate: deactivateDrawing,
        submit: submitDrawing,
        submitError: createShapeResult.error || updateShapeResult.error,
        isSubmitting: isSubmitting,
        shapeToEdit: shapeIdToEdit,
        isMovingShapes: isMovingShapes,
        setMovingShapes: setMovingShapes,
        isCopyingShapes: isCopyingShapes,
        setCopyingShapes: setCopyingShapes,
        setIsExtruding,
        setThickness,
        ...drawingState,
      },
      shapes: {
        data: shapesData?.project?.shapes,
        fetching: shapesFetching,
        error: shapesError,
      },
    }),
    [
      activateDrawing,
      createShapeResult.error,
      deactivateDrawing,
      drawingState,
      editShape,
      isCopyingShapes,
      isMovingShapes,
      isSubmitting,
      moveOrCopyShapes,
      shapeIdToEdit,
      shapesData?.project?.shapes,
      shapesError,
      shapesFetching,
      submitDrawing,
      updateShapeResult.error,
    ]
  );

  const { selectedDbIds } = useSelection();

  const selectedShapes = useMemo(
    () =>
      shapes?.filter((shape) => isShapeSelected(shape, selectedDbIds)) ?? [],
    [selectedDbIds, shapes]
  );

  return (
    <ShapesContext.Provider value={contextValue}>
      {children}
      {isDrawingShape ? (
        <ShapeDrawing
          // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
          key={drawingModeKey}
          mode={drawingMode}
          onResult={setDrawingResult}
          initialState={drawingInitialState}
          viewer={viewer}
          thickness={isExtruding ? thickness : undefined}
          hiddenModelUrns={hiddenModelUrns}
        />
      ) : null}
      {viewer && (isMovingShapes || isCopyingShapes) ? (
        <MoveOrCopy3DShapes
          shapes={selectedShapes}
          viewer={viewer}
          onResult={(translationVector) =>
            moveOrCopyShapes(
              selectedShapes.map((shape) => shape.id) as string[],
              translationVector,
              isCopyingShapes
            )
          }
        />
      ) : null}
    </ShapesContext.Provider>
  );
};

export const useBimShapes = () => {
  const context = useContext(ShapesContext);
  if (!context) {
    throw new Error('useShapesContext must be used within a ShapesProvider');
  }
  return context;
};

const getUpdateShapeInput = (
  shapeToEdit: ShapeDeepFragment,
  drawingResult: ShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D
): UpdateShapeInput => {
  switch (drawingResult.type) {
    case ShapeDrawingResultType.Polygon:
      return getUpdatePolygonInput(
        shapeToEdit,
        drawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D
      );
    case ShapeDrawingResultType.ExtrudedPolygon:
      return getUpdateExtrudedPolygonInput(
        shapeToEdit,
        drawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D
      );
    case ShapeDrawingResultType.LineString:
      return getUpdateLineInput(
        shapeToEdit,
        drawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D
      );
    case ShapeDrawingResultType.Point:
      return getUpdatePointInput(
        shapeToEdit,
        drawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D
      );
  }
};

const getMultipolygonFromDrawingResult = (
  drawingResult: PolygonShapeDrawingResult
): MultiPolygon3Type => {
  invariant(drawingResult);
  invariant(drawingResult.valid);
  return {
    polygons: drawingResult.multipolygon.polygons.map((polygon) => ({
      exterior: {
        points: polygon.exterior.map((point) => ({
          x: point[0],
          y: point[1],
          z: point[2],
        })),
      },
      interiors: polygon.interiors.map((interior) => ({
        points: interior.map((point) => ({
          x: point[0],
          y: point[1],
          z: point[2],
        })),
      })),
    })),
  };
};

const getUpdatePolygonInput = (
  shapeToEdit: ShapeDeepFragment,
  drawingResult: PolygonShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D
): UpdateShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  invariant(drawingResult);
  invariant(drawingResult.valid);
  invariant(viewer, 'Viewer not loaded');
  return {
    id: shapeToEdit.id,
    patch: {
      polygon: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.polygon!.id,
          patch: {
            plane: {
              a: drawingResult.plane.unitNormal[0],
              b: drawingResult.plane.unitNormal[1],
              c: drawingResult.plane.unitNormal[2],
              d: drawingResult.plane.planeCoefficient,
            },
            multipolygon: getMultipolygonFromDrawingResult(drawingResult),
            unitScale: viewer.model.getUnitScale(),
          },
        },
      },
    },
  };
};

const getUpdateExtrudedPolygonInput = (
  shapeToEdit: ShapeDeepFragment,
  drawingResult: PolygonShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D
): UpdateShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  invariant(drawingResult);
  invariant(drawingResult.valid);
  invariant(viewer, 'Viewer not loaded');
  return {
    id: shapeToEdit.id,
    patch: {
      extrudedPolygon: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.extrudedPolygon!.id,
          patch: {
            plane: {
              a: drawingResult.plane.unitNormal[0],
              b: drawingResult.plane.unitNormal[1],
              c: drawingResult.plane.unitNormal[2],
              d: drawingResult.plane.planeCoefficient,
            },
            thickness: drawingResult.thickness,
            multipolygon: getMultipolygonFromDrawingResult(drawingResult),
            unitScale: viewer.model.getUnitScale(),
          },
        },
      },
    },
  };
};

const getUpdateLineInput = (
  shapeToEdit: ShapeDeepFragment,
  drawingResult: LineStringShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D
): UpdateShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  invariant(viewer, 'Viewer not loaded');
  return {
    id: shapeToEdit.id,
    patch: {
      line: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.line!.id,
          patch: {
            points: drawingResult.points.map((point) => ({
              x: point[0],
              y: point[1],
              z: point[2],
            })),
            unitScale: viewer.model.getUnitScale(),
          },
        },
      },
    },
  };
};

const getUpdatePointInput = (
  shapeToEdit: ShapeDeepFragment,
  drawingResult: PointDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D
): UpdateShapeInput => {
  invariant(shapeToEdit, 'Shape to edit is null');
  invariant(drawingResult.point, 'Point is null');
  invariant(viewer, 'Viewer not loaded');
  return {
    id: shapeToEdit.id,
    patch: {
      shapePoint: {
        updateById: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: shapeToEdit.shapePoint!.id,
          patch: {
            point: {
              x: drawingResult.point[0],
              y: drawingResult.point[1],
              z: drawingResult.point[2],
            },
          },
        },
      },
    },
  };
};

function getCreateShapeInput(
  drawingResult: ShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D,
  projectId: string,
  shapeName: string
): CreateShapeInput {
  switch (drawingResult.type) {
    case ShapeDrawingResultType.Polygon:
      return getCreatePolygonInput(
        drawingResult as PolygonShapeDrawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D,
        projectId,
        shapeName
      );
    case ShapeDrawingResultType.ExtrudedPolygon:
      return getCreateExtrudedPolygonInput(
        drawingResult as PolygonShapeDrawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D,
        projectId,
        shapeName
      );
    case ShapeDrawingResultType.LineString:
      return getCreateLineInput(
        drawingResult as LineStringShapeDrawingResult,
        viewer as Autodesk.Viewing.GuiViewer3D,
        projectId,
        shapeName
      );
    case ShapeDrawingResultType.Point:
      return getCreatePointInput(
        drawingResult as PointDrawingResult,
        projectId,
        shapeName
      );
  }
}

const getCreatePolygonInput = (
  drawingResult: PolygonShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D,
  projectId: string,
  shapeName: string,
  folderId?: string
): CreateShapeInput => {
  invariant(viewer, 'viewer must be defined');
  invariant(drawingResult);
  invariant(drawingResult.valid);

  return {
    shape: {
      projectId,
      name: shapeName,
      urn: getShapeUrn(projectId),
      folder: folderId ?? undefined,
      polygon: {
        create: [
          {
            plane: {
              a: drawingResult.plane.unitNormal[0],
              b: drawingResult.plane.unitNormal[1],
              c: drawingResult.plane.unitNormal[2],
              d: drawingResult.plane.planeCoefficient,
            },
            multipolygon: getMultipolygonFromDrawingResult(drawingResult),
            // Ideally we should get this from the model used for drawing the shape, but it seems like that model always has scale 1
            // and that viewer.model has the correct scale
            unitScale: viewer.model.getUnitScale(),
          },
        ],
      },
    },
  };
};

const getCreateExtrudedPolygonInput = (
  drawingResult: PolygonShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D,
  projectId: string,
  shapeName: string,
  folderId?: string
): CreateShapeInput => {
  invariant(viewer, 'viewer must be defined');
  invariant(drawingResult);
  invariant(drawingResult.valid);

  return {
    shape: {
      projectId,
      name: shapeName,
      urn: getShapeUrn(projectId),
      folder: folderId ?? undefined,
      extrudedPolygon: {
        create: [
          {
            plane: {
              a: drawingResult.plane.unitNormal[0],
              b: drawingResult.plane.unitNormal[1],
              c: drawingResult.plane.unitNormal[2],
              d: drawingResult.plane.planeCoefficient,
            },
            thickness: drawingResult.thickness ?? 3.28084,
            multipolygon: getMultipolygonFromDrawingResult(drawingResult),
            // Ideally we should get this from the model used for drawing the shape, but it seems like that model always has scale 1
            // and that viewer.model has the correct scale
            unitScale: viewer.model.getUnitScale(),
          },
        ],
      },
    },
  };
};

const getCreateLineInput = (
  drawingResult: LineStringShapeDrawingResult,
  viewer: Autodesk.Viewing.GuiViewer3D,
  projectId: string,
  shapeName: string,
  folderId?: string
): CreateShapeInput => {
  return {
    shape: {
      projectId,
      name: shapeName,
      urn: getShapeUrn(projectId),
      folder: folderId ?? undefined,
      line: {
        create: [
          {
            points: drawingResult.points.map((point) => ({
              x: point[0],
              y: point[1],
              z: point[2],
            })),
            // Ideally we should get this from the model used for drawing the shape, but it seems like that model always has scale 1
            // and that viewer.model has the correct scale
            unitScale: viewer.model.getUnitScale(),
          },
        ],
      },
    },
  };
};

const getCreatePointInput = (
  drawingResult: PointDrawingResult,
  projectId: string,
  shapeName: string,
  folderId?: string
): CreateShapeInput => {
  invariant(drawingResult.point, 'Point is null');

  return {
    shape: {
      projectId,
      name: shapeName,
      urn: getShapeUrn(projectId),
      folder: folderId ?? undefined,
      shapePoint: {
        create: [
          {
            point: {
              x: drawingResult.point[0],
              y: drawingResult.point[1],
              z: drawingResult.point[2],
            },
          },
        ],
      },
    },
  };
};
