import { UseMutationExecute } from 'urql';
import {
  Diamond,
  KileTypeSKU,
} from '../components/orderContractor/pdf-shapes/SheetWedgeShapeDrawing';
import {
  BulkUpsertSparkelPropertiesInput,
  BulkUpsertSparkelPropertiesMutation,
  CreateSheetShapeMutation,
  Exact,
  ForgeAttributeQueryTypeInput,
  GetOrderEntryTemplatesPageQuery,
  SheetShapeDeepFragment,
} from '../gql/graphql';
import { SlopedInsulationCrossSection } from '../components/orderContractor/pdf-shapes/SheetShapeDrawing';
import { ResolvedOrderEntry } from '../domain/resolve-order-entry';
import {
  BUILDUP,
  computeBaseLayerFromTrapezoid,
  computeRectangularInsulationFromSlopedSegments,
  computeSlopedInsulationFromTrapezoid,
  computeTopLayerFromTrapezoid,
  computeTrapezoids,
  Trapezoid2,
} from '../components/orderContractor/pdf-shapes/protan/SlopedInsulation';
import { getTemplateForSku } from '../components/orderContractor/ProductChoices';

export const updateWedgeProps = async (
  result: CreateSheetShapeMutation,
  diamond: Diamond,
  kileType: KileTypeSKU,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string
) => {
  if (diamond && result.createSheetShape?.sheetShape) {
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle1',
        thePropertyValue: diamond.triangle1 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle2',
        thePropertyValue: diamond.triangle2 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle3',
        thePropertyValue: diamond.triangle3 ? '1' : '0',
      },
    });
    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Triangle4',
        thePropertyValue: diamond.triangle4 ? '1' : '0',
      },
    });

    bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'KileType',
        thePropertyValue: kileType,
      },
    });
  }
};

// eslint-disable-next-line complexity
export const updateDrainLineProps = async (
  sheetShape: SheetShapeDeepFragment | undefined,
  crossSection: SlopedInsulationCrossSection,
  roofShapeDbId: number | undefined,
  bulkUpsertSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  handleCreateFromTemplateClick: (
    templateId: string,
    title: string,
    query: ForgeAttributeQueryTypeInput | undefined,
    orderId: string
  ) => Promise<void>,
  resourceTemplateData: GetOrderEntryTemplatesPageQuery | undefined,
  resolvedOrderEntries: ResolvedOrderEntry[],
  unitScale: number,
  projectId: string,
  orderId: string
) => {
  if (crossSection && sheetShape && roofShapeDbId) {
    bulkUpsertSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: sheetShape.urn,
            dbIds: [sheetShape.dbId],
          },
        ],
        thePropertySet: 'CrossSection',
        thePropertyValue: JSON.stringify(crossSection),
      },
    });
    bulkUpsertSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: sheetShape.urn,
            dbIds: [sheetShape.dbId],
          },
        ],
        thePropertySet: 'RoofShapeDbId',
        thePropertyValue: roofShapeDbId.toString(),
      },
    });

    const { trapezoids: crossSectionTrapezoids, maxHeight: maxHeightVC } =
      computeTrapezoids(
        crossSection.baseLine,
        crossSection.lowPoints,
        0.26 / unitScale, // 260mm
        crossSection.slope
      );

    const productList = getSlopedInsulationProducts(
      crossSectionTrapezoids,
      unitScale
    );

    const promises = [];

    for (const product of productList) {
      if (product.sku && sheetShape && resourceTemplateData) {
        const shouldCreateFromTemplate = !resolvedOrderEntries.some(
          (orderEntry) =>
            orderEntry.customFields.find(
              (field) => field.resolvedValue.value === product.sku
            )
        );

        if (!shouldCreateFromTemplate) {
          continue;
        }

        const template = getTemplateForSku(product.sku, resourceTemplateData);

        if (template) {
          const templateCreationPromise = handleCreateFromTemplateClick(
            template.id,
            template.name,
            template.elementIdentifiersQuery || undefined,
            orderId
          );

          promises.push(templateCreationPromise);
        } else {
          console.log(
            `No template found for sku: ${product.sku}. Skipping template creation.`
          );
        }
      }
    }

    await Promise.all(promises);

    console.table(productList);
  }
};

function getSlopedInsulationProducts(
  crossSectionTrapezoids: Trapezoid2[],
  unitScale: number
) {
  const baseLayerProductSegments = crossSectionTrapezoids.flatMap((trapezoid) =>
    computeBaseLayerFromTrapezoid(trapezoid, BUILDUP.baseLayer, unitScale)
  );

  const topLayerProductSegments = crossSectionTrapezoids.flatMap((trapezoid) =>
    computeTopLayerFromTrapezoid(trapezoid, BUILDUP.topLayer, unitScale)
  );

  const slopedInsulationSegmentsPerTrapezoid = crossSectionTrapezoids.map(
    (trapezoid) =>
      computeSlopedInsulationFromTrapezoid(
        trapezoid,
        BUILDUP.slopedInsulationLayer,
        BUILDUP.topLayer,
        unitScale
      )
  );

  const rectangularInsulationSegments = crossSectionTrapezoids.flatMap(
    (trapezoid, index) =>
      computeRectangularInsulationFromSlopedSegments(
        slopedInsulationSegmentsPerTrapezoid[index],
        trapezoid,
        BUILDUP.insulationLayer,
        BUILDUP.baseLayer,
        unitScale
      )
  );

  const baseLayerProductList = baseLayerProductSegments.flatMap((segment) =>
    segment.products.map((product) => product)
  );

  const topLayerProductList = topLayerProductSegments.flatMap((segment) =>
    segment.products.map((product) => product)
  );

  const slopedInsulationProductList =
    slopedInsulationSegmentsPerTrapezoid.flatMap((mainTrapezoid) =>
      mainTrapezoid.flatMap((segment) =>
        segment.products.map((product) => product)
      )
    );

  const rectangularInsulationProductList =
    rectangularInsulationSegments.flatMap((segment) =>
      segment.products.map((product) => product)
    );

  // Group products according to SKU and provide the count
  const productMap = new Map<string, number>();

  const allProducts = [
    ...baseLayerProductList,
    ...topLayerProductList,
    ...slopedInsulationProductList,
    ...rectangularInsulationProductList,
  ];

  allProducts.forEach((product) => {
    const count = productMap.get(product.sku) || 0;
    productMap.set(product.sku, count + 1);
  });

  return Array.from(productMap).map(([sku, count]) => {
    const product = allProducts.find((p) => p.sku === sku);
    return { sku: product?.sku, count };
  });
}

export const setProtanProperties = async (
  result: CreateSheetShapeMutation,
  roofHeight: string | undefined,
  qkast: string | undefined,
  orderId: string,
  fireRequirements: string | undefined,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string
) => {
  if (qkast && result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'QKast',
        thePropertyValue: qkast,
      },
    });
  }
  if (result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'OrderId',
        thePropertyValue: orderId,
      },
    });
  }
  if (roofHeight && result.createSheetShape?.sheetShape) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Roof height',
        thePropertyValue: roofHeight,
      },
    });
  }

  if (
    fireRequirements === 'Combustible' &&
    result.createSheetShape?.sheetShape
  ) {
    await bulkCreateSparkelProps({
      input: {
        projectId,
        dbIds: [
          {
            modelUrn: result?.createSheetShape?.sheetShape?.urn,
            dbIds: [result?.createSheetShape?.sheetShape?.dbId],
          },
        ],
        thePropertySet: 'Fire sectioning',
        thePropertyValue: '-0.72',
      },
    });
  }
};
