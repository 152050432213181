import { SheetShapeDeepFragment } from '../../gql/graphql';
import {
  GeometricResult,
  MultiPolygon,
  MultiPolygon2,
  Plane,
  Point,
  Point2,
  Triangle,
} from './geometric-types';
import { SlopedInsulationCrossSection } from 'src/components/orderContractor/pdf-shapes/SheetShapeDrawing';

// Gometric properties and algorithms should have no overlapping names
// Because we currently have no other way to separate them than string name...
export enum MeshAlgorithmType {
  VERTICAL_COMPONENT = 'Vertical component',
  BOUNDING_BOX_LENGTH = 'Bounding box length',
  BOUNDING_BOX_HORIZONTAL_LENGTH = 'Bounding box horizontal length',
  BOUNDING_BOX_HORIZONTAL_WIDTH = 'Bounding box horizontal width',
  LARGEST_FLAT_SIDE_AREA = 'Largest flat surface',
  CONVEX_LARGEST_FLAT_SIDE_AREA = 'Gross side area include depth',
  CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY = 'Gross side area ignore depth',
  LARGEST_SURFACE_AREA = 'Largest surface area',
  LARGEST_SURFACE_AREA_WITHOUT_OPENINGS = 'Largest surface area without openings',
  TOP_SURFACE_AREA = 'Top surface area',
  BOTTOM_SURFACE_AREA = 'Bottom surface area',
  TOTAL_MESH_AREA = 'Total surface area',
  TETRAHEDRON_VOLUME = 'Summed tetrahedron volume',
  MESH_PERIMETER = 'Mesh perimeter',
}

export enum MultiPolygonAlgorithmType {
  POLYGON_VERTICAL_COMPONENT = 'Shapes vertical component',
  POLYGON_PERIMETER = 'Shapes perimeter',
  POLYGON_AREA = 'Shapes largest side area',
  POLYGON_GROSS_SIDE_AREA = 'Shapes gross side area',
}

export enum ExtrudedPolygonAlgorithmType {
  EXTRUDED_POLYGON_PERIMETER = 'Extruded shape base perimeter',
  EXTRUDED_POLYGON_AREA = 'Extruded shape base area',
  EXTRUDED_POLYGON_THICKNESS = 'Extruded shape thickness',
  EXTRUDED_POLYGON_VOLUME = 'Extruded shape volume',
  EXTRUDED_POLYGON_SIDE_AREA = 'Extruded shape side area',
}

export enum PolylineAlgorithmType {
  LINE_LENGTH = 'Line length',
}

export enum MultiPolygon2AlgorithmType {
  POLYGON2_PERIMETER = 'Sheet shapes perimeter',
  POLYGON2_AREA = 'Sheet shapes largest side area',
  POLYGON2_GROSS_SIDE_AREA = 'Sheet shapes gross side area',
  POLYGON2_FIRE_SECTIONING = 'Sheet shape inner area parameterized',
  POLYGON2_CORNER_AREA = 'Sheet shape corner area parameterized',
  POLYGON2_RAND_AREA = 'Sheet shape rand area parameterized',
}

export enum Polyline2AlgorithmType {
  LINE2_LENGTH = 'Sheet line length',
  LINE2_CROSS_SECTION_BASELINE_LENGTH = 'Sheet line cross section baseline length',
  LINE2_CROSS_SECTION_DIAGONAL_LENGTH = 'Sheet line cross section diagonal length',
}

export type GeometricAlgorithmType =
  | MeshAlgorithmType
  | MultiPolygonAlgorithmType
  | ExtrudedPolygonAlgorithmType
  | PolylineAlgorithmType
  | MultiPolygon2AlgorithmType
  | Polyline2AlgorithmType;

export const geometricAlgorithmTypes = [
  ...Object.values(MeshAlgorithmType),
  ...Object.values(PolylineAlgorithmType),
  ...Object.values(MultiPolygonAlgorithmType),
  ...Object.values(ExtrudedPolygonAlgorithmType),
  ...Object.values(MultiPolygon2AlgorithmType),
  ...Object.values(Polyline2AlgorithmType),
];
export const isGeometricAlgorithmType = (
  propertyName: string
): propertyName is GeometricAlgorithmType => {
  return geometricAlgorithmTypes.includes(
    propertyName as GeometricAlgorithmType
  );
};

export const isMeshAlgorithm = (
  algorithmType: GeometricAlgorithmType
): algorithmType is MeshAlgorithmType =>
  Object.values(MeshAlgorithmType).includes(algorithmType as MeshAlgorithmType);

export const isPolygonAlgorithm = (
  algorithmType: GeometricAlgorithmType
): algorithmType is MultiPolygonAlgorithmType =>
  Object.values(MultiPolygonAlgorithmType).includes(
    algorithmType as MultiPolygonAlgorithmType
  );

export const isExtrudedPolygonAlgorithm = (
  algorithmType: GeometricAlgorithmType
): algorithmType is ExtrudedPolygonAlgorithmType =>
  Object.values(ExtrudedPolygonAlgorithmType).includes(
    algorithmType as ExtrudedPolygonAlgorithmType
  );

export const isPolylineAlgorithm = (
  algorithmType: GeometricAlgorithmType
): algorithmType is PolylineAlgorithmType =>
  Object.values(PolylineAlgorithmType).includes(
    algorithmType as PolylineAlgorithmType
  );

export const isPolygon2Algorithm = (
  algorithmType: GeometricAlgorithmType
): algorithmType is MultiPolygon2AlgorithmType =>
  Object.values(MultiPolygon2AlgorithmType).includes(
    algorithmType as MultiPolygon2AlgorithmType
  );

export const isPolyline2Algorithm = (
  algorithmType: GeometricAlgorithmType
): algorithmType is Polyline2AlgorithmType =>
  Object.values(Polyline2AlgorithmType).includes(
    algorithmType as Polyline2AlgorithmType
  );

// An algorithm that operates on a mesh
export type MeshAlgorithm<T extends GeometricResult = GeometricResult> = (
  triangles: Triangle[]
) => T;

// An algorithm that operates on a set of points
export type PointCloudAlgorithm<T extends GeometricResult = GeometricResult> = (
  points: Point[]
) => T;

// An algorithm that operates on a polygon
export type MultiPolygonAlgorithm<T extends GeometricResult = GeometricResult> =
  (polygon: MultiPolygon, plane: Plane) => T;

// An algorithm that operates on an extruded polygon
export type ExtrudedPolygonAlgorithm<
  T extends GeometricResult = GeometricResult
> = (polygon: MultiPolygon, plane: Plane, thickness: number) => T;

// An algorithm that operates on a polyline
export type PolylineAlgorithm<T extends GeometricResult = GeometricResult> = (
  points: Point[]
) => T;

// An algorithm that operates on a 2d polygon
export type MultiPolygon2Algorithm<
  T extends GeometricResult = GeometricResult
> = (polygon: MultiPolygon2) => T;

export type ParameterizedMultiPolygon2Algorithm<
  T extends GeometricResult = GeometricResult
> = (
  polygon: MultiPolygon2,
  buffer: number,
  externalShapes: SheetShapeDeepFragment[],
  unitScale: number
) => T;

export type CornerMultiPolygon2Algorithm<
  T extends GeometricResult = GeometricResult
> = (
  polygon: MultiPolygon2,
  roofHeight: number | undefined,
  unitScale: number
) => T;

export type RandMultiPolygon2Algorithm<
  T extends GeometricResult = GeometricResult
> = (
  polygon: MultiPolygon2,
  roofHeight: number | undefined,
  unitScale: number
) => T;

// An algorithm that operates on a 2d polyline
export type Polyline2Algorithm<T extends GeometricResult = GeometricResult> = (
  points: Point2[]
) => T;

export type SlopedInsulationAlgorithm<
  T extends GeometricResult = GeometricResult
> = (crossSection: SlopedInsulationCrossSection) => T;
