import { useMemo } from 'react';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { useQuery, useSubscription } from 'urql';
import { useExportState } from '../components/common/ExportStateManagerContext';
import { ForgeViewerProvider } from '../components/common/ForgeViewer';
import SheetViewerProvider from '../components/common/SheetViewer';
import {
  ViewerMode,
  ViewerModeContextValue,
  ViewerModeProvider,
} from '../components/common/viewer-mode';
import { Layout } from '../components/layouts/layouts';
import {
  GetProjectSettingsDocument,
  ModelProcessingDocument,
} from '../gql/graphql';
import { useLocalStorage } from '../hooks/local-storage';
import { ForgeContextMenuProvider } from '../services/viewer/ForgeContextMenu';
import { SheetContextMenuProvider } from '../services/viewer/SheetsContextMenu';
import { ShapeFoldersProvider } from '../hooks/shape-folders';
import { BimShapesProvider } from '../hooks/bim-shapes';
import { SheetShapesProvider } from '../hooks/sheet-shapes';
import { useUserTenant } from '../services/auth-info';
import { ProtanProjectStepsProvider } from '../components/common/ProtanProjectStepsProvider';
import { ClearOtherViewerSelection } from './ClearOtherViewerSelection';
import { ExportProjectData } from './ExportProjectData';
import ShapeLabelRenderer from './ShapeLabelRenderer';
import { AutogeneratedRoofProvider } from 'src/hooks/autogenerated-roofs-provider';
import { AutoShapesProvider } from 'src/hooks/auto-shapes';

interface ProjectParams {
  projectId: string;
}

type ProjectPageHandle =
  | {
      hideViewer?: boolean;
    }
  | undefined;

export default function ProjectPage() {
  const matches = useMatches();
  const { projectId } = useParams<keyof ProjectParams>() as ProjectParams;

  const shouldHideViewer = useMemo(
    () => matches.some((m) => (m.handle as ProjectPageHandle)?.hideViewer),
    [matches]
  );

  const [{ data: getProjectSettings }] = useQuery({
    query: GetProjectSettingsDocument,
    variables: { projectId },
  });

  // Listen to model changes. The URQL cache will handle updates, so we don't need to use the result here
  useSubscription({
    query: ModelProcessingDocument,
    variables: {
      projectId,
    },
  });

  const globalOffset = useMemo(
    () => getProjectSettings?.project?.settings?.viewerGlobalOffset ?? null,
    [getProjectSettings]
  );

  const { isProtan } = useUserTenant();

  const [viewerMode, setViewerMode] = useLocalStorage<ViewerMode>(
    `viewer-mode-${projectId}`,
    isProtan ? 'sheets' : 'models'
  );

  const viewerModeContextValue: ViewerModeContextValue = useMemo(
    () => ({
      viewerMode,
      setViewerMode,
    }),
    [setViewerMode, viewerMode]
  );

  const { isExporting } = useExportState();

  return (
    <ViewerModeProvider value={viewerModeContextValue}>
      <ForgeContextMenuProvider>
        <SheetContextMenuProvider>
          <Layout>
            <SheetViewerProvider
              projectId={projectId}
              shouldHideViewer={shouldHideViewer}
            >
              <ForgeViewerProvider
                shouldHideViewer={shouldHideViewer}
                globalOffset={globalOffset}
                projectId={projectId}
              >
                <ShapeFoldersProvider>
                  <AutoShapesProvider projectId={projectId}>
                    <BimShapesProvider>
                      <ProtanProjectStepsProvider
                        shouldIgnore={!isProtan}
                        projectId={projectId}
                      >
                        <AutogeneratedRoofProvider>
                          <SheetShapesProvider>
                            <>
                              {isExporting ? <ExportProjectData /> : undefined}
                              <ClearOtherViewerSelection />
                              <ShapeLabelRenderer />
                              <Outlet />
                            </>
                          </SheetShapesProvider>
                        </AutogeneratedRoofProvider>
                      </ProtanProjectStepsProvider>
                    </BimShapesProvider>
                  </AutoShapesProvider>
                </ShapeFoldersProvider>
              </ForgeViewerProvider>
            </SheetViewerProvider>
          </Layout>
        </SheetContextMenuProvider>
      </ForgeContextMenuProvider>
    </ViewerModeProvider>
  );
}
