import { useCallback } from 'react';
import { useQuery } from 'urql';
import {
  GetShapesForProjectDeepDocument,
  GetSheetShapesForProjectDeepDocument,
} from '../gql/graphql';
import {
  findNextAvailableName,
  findNextAvailableNameForCopy,
} from '../utils/naming-utils';
import { ShapeDrawingResultType } from '../components/orderContractor/shapes/ShapeDrawing';

type UseShapesReturn = {
  getNewShapeName: (
    tenant: string,
    drawingResultType: ShapeDrawingResultType
  ) => string;
  getDuplicatedShapeName: (nameToCopy: string) => string;
};

// Hook for functionality that is shared between bim shapes and sheet shapes
export const useShapes = (projectId: string): UseShapesReturn => {
  const [{ data: shapesData, error: shapesError, fetching: shapesFetching }] =
    useQuery({
      query: GetShapesForProjectDeepDocument,
      variables: { projectId },
    });

  const [
    {
      data: sheetShapesData,
      error: sheetShapesError,
      fetching: sheetShapesFetching,
    },
  ] = useQuery({
    query: GetSheetShapesForProjectDeepDocument,
    variables: { projectId },
  });

  const getNewShapeName = useCallback(
    (tenant: string, drawingResultType: ShapeDrawingResultType) => {
      const existingNames = [
        ...(shapesData?.project?.shapes ?? []).map((shape) => shape.name),
        ...(sheetShapesData?.project?.sheetShapes ?? []).map(
          (shape) => shape.name
        ),
      ];

      // Question: Can we rename these to English?
      if (tenant === 'Protan') {
        if (drawingResultType === ShapeDrawingResultType.Polygon) {
          return findNextAvailableName('Zone', existingNames);
        } else if (drawingResultType === ShapeDrawingResultType.Point) {
          return 'Drain';
        } else if (drawingResultType === ShapeDrawingResultType.Wedge) {
          return 'Wedge';
        } else if (drawingResultType === ShapeDrawingResultType.DrainLine) {
          return 'Tapered Insulation';
        }
      }
      return findNextAvailableName('Shape', existingNames);
    },
    [shapesData?.project?.shapes, sheetShapesData?.project?.sheetShapes]
  );

  const getDuplicatedShapeName = useCallback(
    (nameToCopy: string) => {
      const existingNames = [
        ...(shapesData?.project?.shapes ?? []).map((shape) => shape.name),
        ...(sheetShapesData?.project?.sheetShapes ?? []).map(
          (shape) => shape.name
        ),
      ];

      return findNextAvailableNameForCopy(nameToCopy, existingNames);
    },
    [shapesData?.project?.shapes, sheetShapesData?.project?.sheetShapes]
  );

  return {
    getNewShapeName,
    getDuplicatedShapeName,
  };
};
