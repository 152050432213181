import { Map as ImmutableMap, is as immutableIs } from 'immutable';
import { useEffect, useRef, useState } from 'react';

export type DbIdsPerModelMap = ImmutableMap<string, number[]>;

export const useViewerSelection = (
  viewer: Autodesk.Viewing.Viewer3D | null
): DbIdsPerModelMap => {
  const [selection, setSelection] = useState<DbIdsPerModelMap>(ImmutableMap());
  const selectionRef = useRef<DbIdsPerModelMap>(ImmutableMap());

  useEffect(() => {
    if (!viewer) {
      return;
    }

    const onSelectionChanged = () => {
      const aggregateSelection = viewer.getAggregateSelection();

      let newSelection = ImmutableMap<string, number[]>();

      for (const { model, selection: sel } of aggregateSelection) {
        const urn = model.getData().urn as string;
        newSelection = newSelection.set(urn, sel.slice()); // Copy the selection array
      }

      // Use Immutable.is for efficient deep equality check
      if (!immutableIs(newSelection, selectionRef.current)) {
        selectionRef.current = newSelection;
        setSelection(newSelection);
      }
    };

    viewer.addEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      onSelectionChanged
    );

    // Initialize selection state
    onSelectionChanged();

    return () => {
      viewer.removeEventListener(
        Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
        onSelectionChanged
      );
    };
  }, [viewer]);

  return selection;
};
