import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LineSegment2,
  Point2,
  Polygon2,
} from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import {
  areSegmentsEqual,
  calculateRelativePosition,
  generateIdsForPoints,
  getDistanceToSegment,
  getSegmentIntersection,
  isPoint2,
  pointsAreEqual,
  projectPointOnSegment,
} from '../shapes/util';
import { getUnitScaleFromCalibration } from '../../../domain/sheet-calibration';
import {
  GetSparkelPropertiesForProjectQuery,
  SheetShapeDeepFragment,
} from '../../../gql/graphql';
import { toPoint2 } from '../../../domain/geometry/algorithms/util/polygon';
import { ClearableSheetEdge } from './SheetEdge';
import {
  InitialShapeDrawingState,
  ShapeDrawingMode,
  DrainLineDrawingResult,
  SlopedInsulationCrossSection,
} from './SheetShapeDrawing';
import { IntermediateEdges } from './SheetPolygonShapeDrawing';
import { SheetPolygon } from './SheetPolygon';
import {
  BUILDUP,
  computeTrapezoids,
  Trapezoid2,
  trapezoidToPolygon2,
} from './protan/SlopedInsulation';

export const VERTICAL_MULTIPLIER = 5;
const MIN_HEIGHT = 0.26; // 26 cm
const SLOPE = 0.025; // 1:40;
const OFFSET_FROM_ROOF = 50;

// eslint-disable-next-line complexity
export const SheetDrainLineDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.DrainLine];
  onResult: (result: DrainLineDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const initialDrainLines = useMemo(() => {
    return initialState?.points
      ? Array.from(
          { length: initialState.points.length / 2 },
          (_, i) =>
            [
              initialState.points[2 * i],
              initialState.points[2 * i + 1],
            ] as LineSegment2
        )
      : [];
  }, [initialState]);

  const [drainLines, setDrainLines] =
    useState<LineSegment2[]>(initialDrainLines);

  const [intermediateEdge, setIntermediateEdge] = useState<LineSegment2 | null>(
    null
  );

  const [baseLine, setBaseLine] = useState<LineSegment2 | null>(
    initialState?.crossSection?.baseLine || null
  );
  const [interMediateCrossSection, setIntermediateCrossSection] = useState<
    Polygon2[] | null
  >(null);
  const [minHeight] = useState<number>(
    initialState?.crossSection?.minHeight || MIN_HEIGHT
  );

  const {
    calibration: { calibration },
    shapesManager: { renderedSheetShapes },
  } = useSheetViewer();

  const initialActiveShape = useMemo(() => {
    if (initialState?.points && renderedSheetShapes) {
      const pointOnShapeEdge = initialState.points[0];

      // Find the shape that contains the point
      for (const urn in renderedSheetShapes) {
        const shapesForUrn = renderedSheetShapes[urn];
        for (const shape of shapesForUrn) {
          const polygons = shape.sheetShapePolygon?.multipolygon.polygons;
          if (!polygons) {
            continue;
          }
          for (const polygon of polygons) {
            const edges = polygon.exterior.points.map(
              (point, index) =>
                [
                  toPoint2(point),
                  toPoint2(
                    polygon.exterior.points[
                      (index + 1) % polygon.exterior.points.length
                    ]
                  ),
                ] as LineSegment2
            );

            for (const edge of edges) {
              const distance = getDistanceToSegment(
                pointOnShapeEdge,
                edge
              ) as number;
              if (distance < 0.01) {
                return shape;
              }
            }
          }
        }
      }
    }

    return null;
  }, [initialState, renderedSheetShapes]);

  const [activeShape, setActiveShape] = useState<SheetShapeDeepFragment | null>(
    initialActiveShape
  );

  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : null,
    [calibration]
  );

  const existingPolygonShapes = useMemo(() => {
    return Object.values(renderedSheetShapes).flatMap((shapesForUrn) =>
      shapesForUrn.filter((shape) => shape.sheetShapePolygon !== null)
    );
  }, [renderedSheetShapes]);

  const emitResult = useCallback(
    (
      drainLines: LineSegment2[],
      crossSection: SlopedInsulationCrossSection | undefined,
      roofShapeDbId?: number
    ) => {
      if (crossSection) {
        onResult({
          drainLines,
          valid: true,
          crossSection,
          roofShapeDbId,
        });
      } else {
        onResult({
          drainLines,
          valid: false,
          crossSection,
          roofShapeDbId,
        });
      }
    },
    [onResult]
  );

  const crossSection = useMemo(() => {
    if (
      drainLines &&
      drainLines.length > 0 &&
      baseLine &&
      activeShape &&
      unitScale
    ) {
      const lastDrainLine = drainLines[drainLines.length - 1];
      const offsetDirection = [
        lastDrainLine[1][0] - lastDrainLine[0][0],
        lastDrainLine[1][1] - lastDrainLine[0][1],
      ];

      const offsetDirectionMagnitude = Math.sqrt(
        offsetDirection[0] ** 2 + offsetDirection[1] ** 2
      );
      const offsetDirectionNormalized: Point2 = [
        offsetDirection[0] / offsetDirectionMagnitude,
        offsetDirection[1] / offsetDirectionMagnitude,
      ];

      const lowPoints = drainLines.map((drainLine) =>
        calculateRelativePosition(drainLine[0], baseLine)
      );

      const crossSectionTrapezoids = computeTrapezoids(
        baseLine,
        lowPoints,
        (minHeight * VERTICAL_MULTIPLIER) / unitScale,
        SLOPE * VERTICAL_MULTIPLIER
      );

      const offsettedTrapezoids = crossSectionTrapezoids.trapezoids.map(
        (trapezoid) =>
          trapezoid.map((point) => [
            point[0] -
              offsetDirectionNormalized[0] *
                (OFFSET_FROM_ROOF + crossSectionTrapezoids.maxHeight),
            point[1] -
              offsetDirectionNormalized[1] *
                (OFFSET_FROM_ROOF + crossSectionTrapezoids.maxHeight),
          ]) as Trapezoid2
      );

      emitResult(
        drainLines,
        {
          baseLine,
          slope: SLOPE,
          lowPoints,
          minHeight,
        },
        activeShape.dbId
      );

      return offsettedTrapezoids.map((trapezoid) =>
        trapezoidToPolygon2(trapezoid)
      );
    } else {
      return null;
    }
  }, [activeShape, baseLine, drainLines, emitResult, minHeight, unitScale]);

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [getPointInPdfCoordinateSystem]
  );

  const handleViewerClick = useCallback(
    // eslint-disable-next-line complexity
    (event: MouseEvent) => {
      if (event.button !== 0 || !unitScale) {
        return;
      }

      const newPoint = getPoint(event);

      // If we already have a crossSection, create parallel split segment
      if (crossSection && baseLine && activeShape && drainLines) {
        const drainLinesDirection = [
          drainLines[0][0][0] - drainLines[0][1][0],
          drainLines[0][0][1] - drainLines[0][1][1],
        ] as Point2;

        const splitSegment = getDirectedSegmentFromPointInShape(
          newPoint,
          drainLinesDirection,
          activeShape
        );

        if (splitSegment) {
          setDrainLines([...drainLines, splitSegment]);
        }
      } else {
        const { segment: splitSegment, shape } = getSplitSegmentFromPoint(
          newPoint,
          existingPolygonShapes
        );

        if (splitSegment && shape) {
          const newBaseLine = getBaseLineFromSplitSegment(
            splitSegment,
            shape,
            newPoint
          );

          if (newBaseLine) {
            setBaseLine(newBaseLine);
            setActiveShape(shape);
            setDrainLines([...drainLines, splitSegment]);
          }
        }
      }
    },
    [
      activeShape,
      baseLine,
      crossSection,
      drainLines,
      existingPolygonShapes,
      getPoint,
      unitScale,
    ]
  );

  const { viewerRef } = useSheetViewer();

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handlePointerMove = useCallback(
    // eslint-disable-next-line complexity
    (event: MouseEvent) => {
      const newPoint = getPoint(event);

      // If we already have a crossSection, create parallel split segment
      if (crossSection && baseLine && activeShape && drainLines) {
        setIntermediateCrossSection(null);

        const drainLinesDirection = [
          drainLines[0][1][0] - drainLines[0][0][0],
          drainLines[0][1][1] - drainLines[0][0][1],
        ] as Point2;

        const splitSegment = getDirectedSegmentFromPointInShape(
          newPoint,
          drainLinesDirection,
          activeShape
        );

        if (splitSegment) {
          setIntermediateEdge(splitSegment);
        }
      } else {
        // Original logic for first movement
        if (crossSection) {
          setIntermediateEdge(null);
          setIntermediateCrossSection(null);
          return;
        }

        const { segment: splitSegment, shape } = getSplitSegmentFromPoint(
          newPoint,
          existingPolygonShapes
        );

        if (splitSegment && shape && unitScale) {
          setIntermediateEdge(splitSegment);

          const newBaseLine = getBaseLineFromSplitSegment(
            splitSegment,
            shape,
            newPoint
          );

          if (newBaseLine) {
            const lowPoints = drainLines.map((drainLine) =>
              calculateRelativePosition(drainLine[0], newBaseLine)
            );

            lowPoints.push(calculateRelativePosition(newPoint, newBaseLine));

            const crossSectionTrapezoids = computeTrapezoids(
              newBaseLine,
              lowPoints,
              (minHeight * VERTICAL_MULTIPLIER) / unitScale,
              SLOPE * VERTICAL_MULTIPLIER
            );

            const offsetDirection = [
              newPoint[0] - splitSegment[0][0],
              newPoint[1] - splitSegment[0][1],
            ];
            const offsetDirectionMagnitude = Math.sqrt(
              offsetDirection[0] ** 2 + offsetDirection[1] ** 2
            );
            const offsetDirectionNormalized: Point2 = [
              offsetDirection[0] / offsetDirectionMagnitude,
              offsetDirection[1] / offsetDirectionMagnitude,
            ];

            const offsettedTrapezoids = crossSectionTrapezoids.trapezoids.map(
              (trapezoid) =>
                trapezoid.map((point) => [
                  point[0] -
                    offsetDirectionNormalized[0] *
                      (OFFSET_FROM_ROOF + crossSectionTrapezoids.maxHeight),
                  point[1] -
                    offsetDirectionNormalized[1] *
                      (OFFSET_FROM_ROOF + crossSectionTrapezoids.maxHeight),
                ]) as Trapezoid2
            );

            setIntermediateCrossSection(
              offsettedTrapezoids.map((trapezoid) =>
                trapezoidToPolygon2(trapezoid)
              )
            );
          } else {
            setIntermediateCrossSection(null);
          }
        }
      }
    },
    [
      getPoint,
      crossSection,
      baseLine,
      activeShape,
      drainLines,
      existingPolygonShapes,
      unitScale,
      minHeight,
    ]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  return (
    <>
      {drainLines.map((drainLine, index) => {
        return (
          <ClearableSheetEdge
            // todo: index is not a good key. use a unique id
            key={index}
            onClear={() => {
              const newDrainLines = [...drainLines];
              newDrainLines.splice(index, 1);
              setDrainLines(newDrainLines);
              emitResult(newDrainLines, undefined);
            }}
            segment={drainLine}
            cursor={'pointer'}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        );
      })}
      {crossSection && unitScale ? (
        <SheetPolygon
          multipolygon={{
            polygons: crossSection,
          }}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'green.200'}
          stroke={'green.400'}
        />
      ) : null}
      {intermediateEdge && unitScale
        ? IntermediateEdges(
            intermediateEdge[0],
            generateIdsForPoints([intermediateEdge[1]]),
            false,
            'orange.300',
            getPointInDomCoordinateSystem
          )
        : null}
      {interMediateCrossSection && unitScale ? (
        <SheetPolygon
          multipolygon={{
            polygons: interMediateCrossSection,
          }}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'orange.200'}
          stroke={'orange.400'}
        />
      ) : null}
    </>
  );
};

function getClosestEdge(
  point: Point2,
  edges: LineSegment2[]
): LineSegment2 | null {
  let closestEdge: LineSegment2 | null = null;
  let minDistance = Infinity;

  edges.forEach((edge) => {
    const distance = getDistanceToSegment(point, edge);
    if (distance < minDistance) {
      minDistance = distance;
      closestEdge = edge;
    }
  });

  return closestEdge;
}

// eslint-disable-next-line complexity
function getSplitSegmentFromPoint(
  point: Point2,
  shapes: SheetShapeDeepFragment[]
): { segment: LineSegment2 | null; shape: SheetShapeDeepFragment | null } {
  const candidates: [LineSegment2, SheetShapeDeepFragment][] = [];
  for (const shape of shapes) {
    const polygons = shape.sheetShapePolygon?.multipolygon.polygons;
    if (!polygons) {
      continue;
    }
    for (const polygon of polygons) {
      const edges = polygon.exterior.points.map(
        (point, index) =>
          [
            toPoint2(point),
            toPoint2(
              polygon.exterior.points[
                (index + 1) % polygon.exterior.points.length
              ]
            ),
          ] as LineSegment2
      );

      const closestEdgeForThisPolygon = getClosestEdge(point, edges);

      if (closestEdgeForThisPolygon) {
        candidates.push([closestEdgeForThisPolygon, shape]);
        break;
      }
    }
  }

  const [closestEdge, closestShape] = candidates.reduce((prev, curr) =>
    getDistanceToSegment(point, curr[0]) < getDistanceToSegment(point, prev[0])
      ? curr
      : prev
  );

  if (closestEdge && closestShape) {
    const projectedPoint = projectPointOnSegment(point, closestEdge);

    const directionVector: Point2 = [
      point[0] - projectedPoint[0],
      point[1] - projectedPoint[1],
    ];

    const magnitude = Math.sqrt(
      directionVector[0] ** 2 + directionVector[1] ** 2
    );
    const normalizedDirection: Point2 = [
      directionVector[0] / magnitude,
      directionVector[1] / magnitude,
    ];

    const extensionMultiplier = 10000;

    const extendedPoint: Point2 = [
      projectedPoint[0] + normalizedDirection[0] * extensionMultiplier,
      projectedPoint[1] + normalizedDirection[1] * extensionMultiplier,
    ];

    let intersectionPoint: Point2 | null = null;

    const polygonEdges =
      closestShape.sheetShapePolygon?.multipolygon.polygons.flatMap(
        (polygon) => {
          const edges = polygon.exterior.points.map(
            (point, index) =>
              [
                toPoint2(point),
                toPoint2(
                  polygon.exterior.points[
                    (index + 1) % polygon.exterior.points.length
                  ]
                ),
              ] as LineSegment2
          );
          return edges;
        }
      ) || [];

    for (const edge of polygonEdges) {
      if (!areSegmentsEqual(edge, closestEdge, 0.01)) {
        const potentialIntersection = getSegmentIntersection(
          [projectedPoint, extendedPoint],
          edge
        );
        if (isPoint2(potentialIntersection)) {
          intersectionPoint = potentialIntersection as Point2;
          break;
        }
      }
    }

    if (intersectionPoint) {
      const edge = [projectedPoint, intersectionPoint] as LineSegment2;
      return { segment: edge, shape: closestShape };
    }

    return { segment: null, shape: null };
  }

  return { segment: null, shape: null };
}

// eslint-disable-next-line complexity
function getDirectedSegmentFromPointInShape(
  point: Point2,
  direction: Point2,
  shape: SheetShapeDeepFragment
): LineSegment2 | null {
  // Normalize the direction vector
  const magnitude = Math.sqrt(direction[0] ** 2 + direction[1] ** 2);
  const normalizedDirection: Point2 = [
    direction[0] / magnitude,
    direction[1] / magnitude,
  ];

  const extensionMultiplier = 1000;

  // Create extended points in both directions
  const extendedPoint1: Point2 = [
    point[0] + normalizedDirection[0] * extensionMultiplier,
    point[1] + normalizedDirection[1] * extensionMultiplier,
  ];

  const extendedPoint2: Point2 = [
    point[0] - normalizedDirection[0] * extensionMultiplier,
    point[1] - normalizedDirection[1] * extensionMultiplier,
  ];

  // Get all edges from the shape
  const polygonEdges =
    shape.sheetShapePolygon?.multipolygon.polygons.flatMap((polygon) => {
      const edges = polygon.exterior.points.map(
        (point, index) =>
          [
            toPoint2(point),
            toPoint2(
              polygon.exterior.points[
                (index + 1) % polygon.exterior.points.length
              ]
            ),
          ] as LineSegment2
      );
      return edges;
    }) || [];

  let intersectionPoint1: Point2 | null = null;
  let intersectionPoint2: Point2 | null = null;
  let minDist1 = Infinity;
  let minDist2 = Infinity;
  const tolerance = 0.01;

  // Check each edge for intersections
  for (const edge of polygonEdges) {
    // Check intersection in first direction
    const potentialIntersection1 = getSegmentIntersection(
      [point, extendedPoint1],
      edge,
      tolerance
    );

    if (
      isPoint2(potentialIntersection1) &&
      !pointsAreEqual(point, potentialIntersection1, tolerance)
    ) {
      const dist = Math.hypot(
        potentialIntersection1[0] - point[0],
        potentialIntersection1[1] - point[1]
      );
      if (dist < minDist1) {
        intersectionPoint1 = potentialIntersection1 as Point2;
        minDist1 = dist;
      }
    }

    // Check intersection in opposite direction
    const potentialIntersection2 = getSegmentIntersection(
      [point, extendedPoint2],
      edge,
      tolerance
    );

    if (
      isPoint2(potentialIntersection2) &&
      !pointsAreEqual(point, potentialIntersection2, tolerance)
    ) {
      const dist = Math.hypot(
        potentialIntersection2[0] - point[0],
        potentialIntersection2[1] - point[1]
      );
      if (dist < minDist2) {
        intersectionPoint2 = potentialIntersection2 as Point2;
        minDist2 = dist;
      }
    }
  }

  // Return the complete segment if we found intersections in both directions
  if (intersectionPoint1 && intersectionPoint2) {
    return [intersectionPoint1, intersectionPoint2];
  }

  return null;
}

function getBaseLineFromSplitSegment(
  splitSegment: LineSegment2,
  shape: SheetShapeDeepFragment,
  cursorPoint: Point2
): LineSegment2 | null {
  // Get splitSegment direction
  const splitDirection: Point2 = [
    splitSegment[1][0] - splitSegment[0][0],
    splitSegment[1][1] - splitSegment[0][1],
  ];

  // Normalize split direction
  const splitLength = Math.sqrt(
    splitDirection[0] ** 2 + splitDirection[1] ** 2
  );
  const splitNormal: Point2 = [
    splitDirection[0] / splitLength,
    splitDirection[1] / splitLength,
  ];

  // Get perpendicular direction (rotate 90 degrees)
  const perpDirection: Point2 = [-splitNormal[1], splitNormal[0]];

  // Collect all points from the shape
  const allPoints: Point2[] = [];
  shape.sheetShapePolygon?.multipolygon.polygons.forEach((polygon) => {
    polygon.exterior.points.forEach((point) => {
      allPoints.push([point.x, point.y]);
    });
  });

  // Project points onto both directions
  const projections = allPoints.map((point) => {
    // Vector from split segment start to point
    const toPoint: Point2 = [
      point[0] - splitSegment[0][0],
      point[1] - splitSegment[0][1],
    ];

    // Project onto perpendicular direction
    const perpProjection =
      toPoint[0] * perpDirection[0] + toPoint[1] * perpDirection[1];

    // Project onto split direction
    const splitProjection =
      toPoint[0] * splitNormal[0] + toPoint[1] * splitNormal[1];

    return {
      perpDistance: perpProjection,
      splitDistance: splitProjection,
      point: point,
    };
  });

  // Find min and max perpendicular projections
  const minPerpProj = projections.reduce(
    (min, curr) => (curr.perpDistance < min.perpDistance ? curr : min),
    projections[0]
  );
  const maxPerpProj = projections.reduce(
    (max, curr) => (curr.perpDistance > max.perpDistance ? curr : max),
    projections[0]
  );

  // Find min and max split direction projections
  const minSplitProj = projections.reduce(
    (min, curr) => (curr.splitDistance < min.splitDistance ? curr : min),
    projections[0]
  );
  const maxSplitProj = projections.reduce(
    (max, curr) => (curr.splitDistance > max.splitDistance ? curr : max),
    projections[0]
  );

  if (!minPerpProj || !maxPerpProj || !minSplitProj || !maxSplitProj)
    return null;

  // Project cursor onto split direction to determine which extreme is closer
  const cursorVector: Point2 = [
    cursorPoint[0] - splitSegment[0][0],
    cursorPoint[1] - splitSegment[0][1],
  ];
  const cursorProjection =
    cursorVector[0] * splitNormal[0] + cursorVector[1] * splitNormal[1];

  // Choose the extreme point closer to cursor projection
  const basePosition =
    Math.abs(cursorProjection - minSplitProj.splitDistance) <
    Math.abs(cursorProjection - maxSplitProj.splitDistance)
      ? minSplitProj
      : maxSplitProj;

  // Create baseline segment at the chosen position
  const baseLine: LineSegment2 = [
    [
      splitSegment[0][0] +
        splitNormal[0] * basePosition.splitDistance +
        perpDirection[0] * maxPerpProj.perpDistance,
      splitSegment[0][1] +
        splitNormal[1] * basePosition.splitDistance +
        perpDirection[1] * maxPerpProj.perpDistance,
    ],
    [
      splitSegment[0][0] +
        splitNormal[0] * basePosition.splitDistance +
        perpDirection[0] * minPerpProj.perpDistance,
      splitSegment[0][1] +
        splitNormal[1] * basePosition.splitDistance +
        perpDirection[1] * minPerpProj.perpDistance,
    ],
  ];

  return baseLine;
}

export const getCrossSectionFromProperties = (
  propertyData: GetSparkelPropertiesForProjectQuery | undefined,
  dbId: number,
  urn: string
) => {
  const crossSectionProperty = propertyData?.project?.sparkelProperties.find(
    (prop) =>
      prop.propertySet === 'CrossSection' &&
      prop.dbId === dbId &&
      prop.modelUrn === urn
  );

  if (!crossSectionProperty) {
    return null;
  }

  const crossSectionJsonString = crossSectionProperty.propertyValue;

  if (!crossSectionJsonString) {
    return null;
  }

  return JSON.parse(crossSectionJsonString) as SlopedInsulationCrossSection;
};

export const getRoofShapeFromProperties = (
  propertyData: GetSparkelPropertiesForProjectQuery | undefined,
  dbId: number,
  urn: string
): number | null => {
  const roofShapeProperty = propertyData?.project?.sparkelProperties.find(
    (prop) =>
      prop.propertySet === 'RoofShapeDbId' &&
      prop.dbId === dbId &&
      prop.modelUrn === urn
  );

  if (!roofShapeProperty) {
    return null;
  }

  const roofShapeDbIdString = roofShapeProperty.propertyValue;

  if (!roofShapeDbIdString) {
    return null;
  }

  const roofShapeDbId = parseInt(roofShapeDbIdString);

  return roofShapeDbId;
};

export const getProductAreasFromProperties = (
  propertyData: GetSparkelPropertiesForProjectQuery | undefined,
  dbId: number,
  urn: string
): Record<string, number> => {
  const productAreas: Record<string, number> = {};

  const skus = [
    BUILDUP.baseLayer.sku,
    ...BUILDUP.insulationLayer.map((layer) => layer.sku),
    ...BUILDUP.slopedInsulationLayer.map((layer) => layer.sku),
    BUILDUP.topLayer.sku,
  ];

  for (const sku of skus) {
    const productAreaProperty = propertyData?.project?.sparkelProperties.find(
      (prop) =>
        prop.propertySet === sku && prop.dbId === dbId && prop.modelUrn === urn
    )?.propertyValue;

    if (productAreaProperty) {
      productAreas[sku] = parseFloat(productAreaProperty);
    }
  }

  return productAreas;
};
