// Super hacky way of getting the triangles in a model based on
// https://forge.autodesk.com/blog/get-volume-and-surface-area-viewer
// but without the dependency on a Viewer instance
import * as math from 'mathjs';

import { getTrianglesForFragId } from './extract-forge-geometry';
import { Point, Triangle } from './geometric-types';
import { unitVector } from './algorithms/util';

// Extracted to separate file to simplify mocking
export const getTriangles = (
  model: Autodesk.Viewing.Model,
  dbId: number
): Triangle[] => {
  let triangles: Triangle[] = [];

  let it = model.getData().instanceTree;
  it.enumNodeFragments(
    dbId,
    (fragId: number) => {
      triangles = triangles.concat(getTrianglesForFragId(model, fragId));
    },
    true
  );
  // For some reason some triangles has zero area, so we filter them out to avoid breaking our algorithms
  // (can't find a plane for a degenerate triangle)
  // https://www.notion.so/Sparkel-fails-to-terminate-for-geomtric-calculations-for-a-few-given-elements-4bcb3de139364b52badaf24144ee7f32
  const validTriangles = triangles.filter(isValidTriangle);
  return dedupTriangles(validTriangles);
};

function isValidTriangle(triangle: Triangle): boolean {
  for (const point of triangle) {
    if (point.some((coord) => !Number.isFinite(coord))) {
      console.warn('Triangle contains NaN or Infinity values');
      return false;
    }
  }

  const line = {
    unitDirection: unitVector(math.subtract(triangle[0], triangle[1])),
    pointOnLine: triangle[0],
  };

  // Check if the unit vector calculation resulted in a valid vector
  if (line.unitDirection.some((coord) => !Number.isFinite(coord))) {
    console.warn('Failed to calculate unit vector');
    return false;
  }

  // Using a tolerance of 0 here actually works,
  // because any other value will be a valid (but possibly very small triangle)
  const isLastPointOnLine = isPointOnLine(line, triangle[2], 0);
  if (isLastPointOnLine) {
    console.warn('Triangle is not valid');
  }
  return !isLastPointOnLine;
}

function isPointOnLine(
  line: {
    unitDirection: Point;
    pointOnLine: Point;
  },
  point: Point,
  tolerance = 0.0001
): boolean {
  // https://www.nagwa.com/en/explainers/939127418581/
  const APVector = math.subtract(point, line.pointOnLine);
  const distance = math.norm(math.cross(APVector, line.unitDirection));
  // This is a more type-safe way of comparing mathjs numbers
  return math.smaller(distance, tolerance) as boolean;
}

function generateTriangleHash(triangle: Triangle) {
  return triangle
    .map(([x, y, z]) => `${x.toFixed(5)},${y.toFixed(5)},${z.toFixed(5)}`)
    .sort()
    .join('|');
}

function dedupTriangles(triangles: Array<Triangle>) {
  const uniqueHashes = new Set();
  const dedupedTriangles = [];

  for (const triangle of triangles) {
    const hash = generateTriangleHash(triangle);
    if (!uniqueHashes.has(hash)) {
      dedupedTriangles.push(triangle);
      uniqueHashes.add(hash);
    }
  }
  return dedupedTriangles;
}
