import { ChakraColor } from './color-util';

export const COLORS = [
  ChakraColor.cyan400,
  ChakraColor.orange200,
  ChakraColor.green300,
  ChakraColor.red400,
  ChakraColor.purple300,
  ChakraColor.pink300,
  ChakraColor.cyan800,
  ChakraColor.orange600,
  ChakraColor.green500,
  ChakraColor.red700,
  ChakraColor.purple600,
  ChakraColor.pink700,
];

export const GRADIENTS = [
  `linear-gradient(to bottom, ${ChakraColor.cyan400}, ${ChakraColor.green300}, ${ChakraColor.orange200}, ${ChakraColor.red400})`, // Heatmap
  `linear-gradient(to bottom, ${ChakraColor.red400}, ${ChakraColor.orange200}, ${ChakraColor.green300}, ${ChakraColor.cyan400})`, // Inverted heatmap
  `linear-gradient(to bottom, ${ChakraColor.red400}, ${ChakraColor.orange200}, ${ChakraColor.green300})`, // Progress
  `linear-gradient(to bottom, ${ChakraColor.green300}, ${ChakraColor.orange200}, ${ChakraColor.red400})`, // Inverted progress
  `linear-gradient(to bottom, ${ChakraColor.cyan800}, ${ChakraColor.cyan400}, ${ChakraColor.cyan200})`,
  `linear-gradient(to bottom, ${ChakraColor.orange700}, ${ChakraColor.orange300}, ${ChakraColor.orange100})`,
  `linear-gradient(to bottom, ${ChakraColor.green700}, ${ChakraColor.green400}, ${ChakraColor.green200})`,
  `linear-gradient(to bottom, ${ChakraColor.red700}, ${ChakraColor.red500}, ${ChakraColor.red200})`,
  `linear-gradient(to bottom, ${ChakraColor.purple700}, ${ChakraColor.purple500}, ${ChakraColor.purple200})`,
  `linear-gradient(to bottom, ${ChakraColor.pink800}, ${ChakraColor.pink400}, ${ChakraColor.pink200})`,
];

export function colorsByLeastUsed(colors: string[]) {
  const colorCounts = COLORS.reduce((acc, color) => {
    acc[color] = colors.filter((c) => c === color).length;
    return acc;
  }, {} as Record<string, number>);
  return Object.entries(colorCounts)
    .sort((a, b) => a[1] - b[1])
    .map((colorCountPair) => colorCountPair[0]);
}

export function findLeastUsedColor(colors: string[]) {
  return colorsByLeastUsed(colors)[0];
}
