import { Box, BoxProps } from '@chakra-ui/react';
import { useDrag } from '@use-gesture/react';
import * as math from 'mathjs';
import { useMemo, useRef, useState } from 'react';
import { LineSegment2, Point2 } from '../../../domain/geometry/geometric-types';
import { SheetMetricLengthLabel } from './SheetMetricLengthLabel';

type EdgeProps = BoxProps & {
  segment: LineSegment2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  hideLengthLabel?: boolean;
};
export const SheetEdge = ({
  segment,
  getPointInDomCoordinateSystem,
  hideLengthLabel,
  ...rest
}: EdgeProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  // Convert from PDF coordinate system to DOM coordinate system
  const segmentInDom = useMemo<LineSegment2>(() => {
    const p1 = getPointInDomCoordinateSystem(segment[0]);
    const p2 = getPointInDomCoordinateSystem(segment[1]);
    return [p1, p2];
  }, [getPointInDomCoordinateSystem, segment]);

  const length = math.distance(segmentInDom[0], segmentInDom[1]) as number;

  if (length < 1) {
    return null;
  }

  const [x1, y1] = segmentInDom[0];
  const [x2, y2] = segmentInDom[1];
  const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
  const offsetY = (ref.current?.getBoundingClientRect()?.height ?? 0) / length;

  return (
    <>
      <Box
        ref={ref}
        position="absolute"
        zIndex={2}
        width="4px"
        backgroundColor="green.500"
        style={{
          top: y1 - offsetY,
          left: x1,
          height: `${length}px`,
          transformOrigin: `1px 0`,
          transform: `rotate(${angle - 90}deg)`,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...rest}
      />
      {!hideLengthLabel && isHovered ? (
        <SheetMetricLengthLabel
          segment={segment}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      ) : null}
    </>
  );
};

type DraggableEdgeProps = EdgeProps & {
  onEdgeDrag?: (event: React.MouseEvent) => void;
};

export const DraggableSheetEdge = ({
  onEdgeDrag,
  ...rest
}: DraggableEdgeProps) => {
  const bindDrag = useDrag<React.MouseEvent<HTMLDivElement>>(
    (state) => {
      if (onEdgeDrag) {
        onEdgeDrag(state.event);
      }
    },
    {
      filterTaps: true,
    }
  );
  return (
    <SheetEdge
      cursor={'move'}
      _hover={{
        filter: 'brightness(1.3)',
      }}
      {...bindDrag()}
      {...rest}
    />
  );
};

type ClearableEdgeProps = DraggableEdgeProps & {
  onClear: () => void;
};

export const ClearableSheetEdge = ({
  onClear,
  ...rest
}: ClearableEdgeProps) => {
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClear();
  };

  return (
    <SheetEdge
      onClick={onClick}
      cursor={'pointer'}
      _hover={{
        filter: 'brightness(1.3)',
      }}
      {...rest}
    />
  );
};
