import * as math from 'mathjs';
import { MultiPolyline2Result, ResultType } from '../../geometric-types';
import { SlopedInsulationCrossSection } from 'src/components/orderContractor/pdf-shapes/SheetShapeDrawing';

export function calculateLine2BaselineLength(
  crossSection: SlopedInsulationCrossSection
): MultiPolyline2Result {
  const baseLineDirection = math.subtract(
    crossSection.baseLine[1],
    crossSection.baseLine[0]
  );
  const value = math.norm(baseLineDirection) as number;

  return {
    type: ResultType.MULTIPOLYLINE2,
    value,
    multiPolyline: { lines: [crossSection.baseLine] },
  };
}
