import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Box } from '@chakra-ui/react';
import {
  MultiPolygon,
  Plane,
  Point,
} from '../../../domain/geometry/geometric-types';

import { LineStringShapeDrawing } from './LineStringShapeDrawing';
import { PolygonShapeDrawing } from './PolygonShapeDrawing';
import { PointShapeDrawing } from './PointShapeDrawing';

export enum ShapeDrawingResultType {
  Invalid = 'Invalid',
  LineString = 'LineString',
  Wedge = 'Wedge',
  Polygon = 'Polygon',
  ExtrudedPolygon = 'ExtrudedPolygon',
  Point = 'Point',
  DrainLine = 'DrainLine',
}
export enum ShapeDrawingMode {
  LineString = 'line-string',
  Polygon = 'polygon',
  MagicPolygon = 'magic-polygon',
  Point = 'point',
}

export type LineStringShapeDrawingResult = {
  points: Point[];
  valid: boolean;
};

export type PolygonShapeDrawingResult =
  | {
      valid: true;
      multipolygon: MultiPolygon;
      plane: Plane;
      thickness?: number;
    }
  | {
      valid: false;
    };

export type PointDrawingResult = {
  point: Point | null;
  valid: boolean;
};

export type ShapeDrawingResult =
  | ({
      type: ShapeDrawingResultType.LineString;
    } & LineStringShapeDrawingResult)
  | ({
      type: ShapeDrawingResultType.Polygon;
    } & PolygonShapeDrawingResult)
  | ({
      type: ShapeDrawingResultType.ExtrudedPolygon;
    } & PolygonShapeDrawingResult)
  | ({
      type: ShapeDrawingResultType.Point;
    } & PointDrawingResult);

export type InitialShapeDrawingState = {
  [ShapeDrawingMode.LineString]: {
    points: Point[];
  };
  [ShapeDrawingMode.Polygon]: {
    multipolygon: MultiPolygon;
    plane: Plane;
    thickness?: number;
  };
  [ShapeDrawingMode.MagicPolygon]: {
    multipolygon: MultiPolygon;
    thickness?: number;
  };
  [ShapeDrawingMode.Point]: {
    point: Point;
  };
};

export type ShapeDrawingProps<Q extends ShapeDrawingMode> = {
  onResult: (result: ShapeDrawingResult) => void;
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  mode: Q;
  initialState?: InitialShapeDrawingState[Q];
  thickness?: number;
  hiddenModelUrns: string[];
};

export const ShapeDrawing = <Q extends ShapeDrawingMode>({
  mode,
  viewer,
  onResult,
  initialState,
  thickness,
  hiddenModelUrns,
}: ShapeDrawingProps<Q>) => {
  const onPolygonResult = useCallback(
    (result: PolygonShapeDrawingResult) => {
      const type =
        result.valid && !!result.thickness
          ? ShapeDrawingResultType.ExtrudedPolygon
          : ShapeDrawingResultType.Polygon;
      onResult({ type, ...result });
    },
    [onResult]
  );

  const onLineStringResult = useCallback(
    (result: LineStringShapeDrawingResult) => {
      onResult({ type: ShapeDrawingResultType.LineString, ...result });
    },
    [onResult]
  );

  const onPointResult = useCallback(
    (result: PointDrawingResult) => {
      onResult({ type: ShapeDrawingResultType.Point, ...result });
    },
    [onResult]
  );

  if (!viewer) {
    return null;
  }

  const viewerContainer = viewer.clientContainer;

  const element = (
    <Box position="absolute" overflow="hidden" w="100%" h="100%">
      {(mode === ShapeDrawingMode.Polygon ||
        mode === ShapeDrawingMode.MagicPolygon) && (
        <PolygonShapeDrawing
          initialState={
            // Not sure why type assertion is needed here
            initialState as InitialShapeDrawingState[ShapeDrawingMode.Polygon]
          }
          viewer={viewer}
          onResult={onPolygonResult}
          isOneClickShapeMode={mode === ShapeDrawingMode.MagicPolygon}
          thickness={thickness}
          hiddenModelUrns={hiddenModelUrns}
        />
      )}
      {mode === ShapeDrawingMode.Point && (
        <PointShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.Point]
          }
          viewer={viewer}
          onResult={onPointResult}
        />
      )}
      {mode === ShapeDrawingMode.LineString && (
        <LineStringShapeDrawing
          initialState={
            initialState as InitialShapeDrawingState[ShapeDrawingMode.LineString]
          }
          viewer={viewer}
          onResult={onLineStringResult}
        />
      )}
    </Box>
  );

  // use a portal to render the shape drawing tool inside the viewer div
  // return element;
  return createPortal(element, viewerContainer);
};
