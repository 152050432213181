import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery } from 'urql';
import {
  AutoShapeAlgorithmType,
  AutoShapeDeepFragment,
  GetAutoShapesForProjectDocument,
  CreateAutoShapeDocument,
  DeleteAutoShapeDocument,
  RunExternalWallFinishes3DDocument,
  ExternalWallFinishes3DInput,
} from 'src/gql/graphql';

interface AutoShapesContextValue {
  autoShapes: AutoShapeDeepFragment[] | undefined;
  isProcessing: boolean;
  error: Error | undefined;
  createAutoShape: (
    name: string,
    algorithmType: AutoShapeAlgorithmType,
    algorithmInput: AutoShapeAlgorithmInput
  ) => Promise<AutoShapeDeepFragment | null>;
  deleteAutoShape: (id: string) => Promise<boolean>;
}

const AutoShapesContext = createContext<AutoShapesContextValue | undefined>(
  undefined
);

type AutoShapesProviderProps = {
  projectId: string;
  children: React.ReactNode;
};

export type AutoShapeAlgorithmInput = ExternalWallFinishes3DInput;

export const AutoShapesProvider: React.FC<AutoShapesProviderProps> = ({
  projectId,
  children,
}) => {
  // Fetch dynamic shapes for the given project
  const [{ data, error }] = useQuery({
    query: GetAutoShapesForProjectDocument,
    variables: { projectId },
  });

  const [, createAutoShapeMutation] = useMutation(CreateAutoShapeDocument);
  const [, deleteAutoShapeMutation] = useMutation(DeleteAutoShapeDocument);

  const [isProcessing, setIsProcessing] = useState(false);

  const autoShapes = useMemo(() => data?.project?.autoShapes || [], [data]);

  const [, runExternalWallFinishes3D] = useMutation(
    RunExternalWallFinishes3DDocument
  );

  const runAutoShapeAlgorithm = useCallback(
    async (
      algorithmType: AutoShapeAlgorithmType,
      algorithmInput: AutoShapeAlgorithmInput
    ) => {
      switch (algorithmType) {
        case AutoShapeAlgorithmType.ExternalWallFinishes_3D:
          console.log('Running ExternalWallFinishes_3D');
          return runExternalWallFinishes3D({ input: algorithmInput });
        case AutoShapeAlgorithmType.InternalWallFinishes_3D:
          throw new Error('Not implemented');
        case AutoShapeAlgorithmType.RoomDetection_2D:
          throw new Error('Not implemented');
        case AutoShapeAlgorithmType.RoomDetection_3D:
          throw new Error('Not implemented');
      }
    },
    [runExternalWallFinishes3D]
  );

  const createAutoShape = useCallback(
    async (
      name: string,
      algorithmType: AutoShapeAlgorithmType,
      algorithmOptions: AutoShapeAlgorithmInput
    ) => {
      try {
        setIsProcessing(true);
        const { data } = await createAutoShapeMutation({
          input: {
            autoShape: {
              name,
              projectId,
              algorithmType,
              algorithmOptions,
            },
          },
        });
        const autoShapeResult = await runAutoShapeAlgorithm(
          algorithmType,
          algorithmOptions
        );

        console.log(autoShapeResult);
        return (
          (data?.createAutoShape?.autoShape as AutoShapeDeepFragment) ?? null
        );
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [createAutoShapeMutation, projectId, runAutoShapeAlgorithm]
  );

  const deleteAutoShape = useCallback(
    async (id: string) => {
      try {
        await deleteAutoShapeMutation({ input: { id } });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [deleteAutoShapeMutation]
  );

  const value = useMemo<AutoShapesContextValue>(
    () => ({
      autoShapes,
      isProcessing,
      error,
      createAutoShape,
      deleteAutoShape,
    }),
    [autoShapes, isProcessing, error, createAutoShape, deleteAutoShape]
  );

  return (
    <AutoShapesContext.Provider value={value}>
      {children}
    </AutoShapesContext.Provider>
  );
};

// Custom hook to use the context
export const useAutoShapes = (): AutoShapesContextValue => {
  const context = useContext(AutoShapesContext);
  if (!context) {
    throw new Error('useAutoShapes must be used within a AutoShapesProvider');
  }
  return context;
};
